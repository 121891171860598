/**
 * Stores, a.k.a. Syncables, are School Connect's "offline-provide-able" data.
 *
 * All stores have at least these attributes:
 * - `client_id`: FE-only, identifies stores
 * - `id`: used by the BE DB to identify stores
 * - `model`: used by the FE, but also by the BE to map to Django models
 * - `sync`: FE-only attribute, tells us whether a store needs syncing
 *
 */

import moment, { Moment, MomentInput } from "moment";
import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { v4 } from "uuid";
import { createSelector } from "reselect";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { toast } from "react-toastify";

import { RootState } from "data-handler/rootReducer";
import reportsDuck, {
  getIsReportLocked,
  getReportByMoment,
} from "data-handler/ducks/reports";
import commoditiesDuck from "data-handler/ducks/commodities";
import deliveryIssuesDuck from "data-handler/ducks/deliveryIssues";
import incidentCausesDuck from "data-handler/ducks/incidentCauses";
import { REFRESH_LAST_ACTION_PERFORMED_AT } from "data-handler/ducks/auth";

import {
  buildNumber,
  deliveryCategory,
  studentAttendanceCategory,
  takeHomeRationCategory,
  toastFormattedMessages,
  AttendanceShift,
} from "SCConstants";

import { sortModels } from "helpers/stores";
import { getCanUseOfflineMode } from "helpers/users";
import { IdToNoMealReasonMapping } from "./noMealReasons";

export const CLEAR_STORES = "CLEAR_STORES";
export const UPDATE = "schoolconnect/stores/UPDATE";
export const REQUEST = "schoolconnect/stores/REQUEST";
export const SUCCESS = "schoolconnect/stores/SUCCESS";
export const FAILURE = "schoolconnect/stores/FAILURE";
export const POST_REQUEST = "schoolconnect/stores/POST_REQUEST";
export const POST_REQUEST_CHUNK = "schoolconnect/stores/POST_REQUEST_CHUNK";
export const POST_SUCCESS = "schoolconnect/stores/POST_SUCCESS";
export const POST_FAILURE = "schoolconnect/stores/POST_FAILURE";
export const RESET_SYNC = "schoolconnect/stores/RESET_SYNC";
export const USER_STORES_REQUEST = "schoolconnect/stores/USER_STORES_REQUEST";
export const USER_STORES_SUCCESS = "schoolconnect/stores/USER_STORES_SUCCESS";
export const USER_STORES_FAILURE = "schoolconnect/stores/USER_STORES_FAILURE";

export const SET_USER_CACHED_STORES =
  "schoolconnect/stores/SET_USER_CACHED_STORES";

type StoreModel =
  | "attendance"
  | "delivery"
  | "enrolment"
  | "incident"
  | "year"
  | "offlineuser"
  | "purchaseDetail";

/** Attributes that are common to every store */
export interface BaseStore {
  id?: string;
  client_id: string;
  build_number: string;
  sync: boolean;
  last_edit?: string;
  error?: any;
  errorDetails?: any;
  type: string;
  section: string;
  object_id: number;
  category: string;
}

export interface AttendanceCommodity {
  commodity: number;
  quantity: string;
  category: string;
}

export interface AttendanceLevel {
  female: number;
  male: number;
  level: string;
  shift: AttendanceShift;
  custom_name: string;
}

export interface MeasureUnit {
  id: number;
  name: string;
  symbol: string;
  decimals: number;
}

export interface AttendanceConsumption {
  meal_provided: boolean;
  no_meal_reasons: undefined | number[];
  commodities: undefined | AttendanceCommodity[];
}

export interface Attendance extends BaseStore {
  consumption: undefined | AttendanceConsumption;
  levels: undefined | AttendanceLevel[];
  model: "attendance";
  occurred_on: string;
}

export interface DeliveryCommodity {
  id: string;
  commodity: number;
  quantity: string;
  measure_unit: MeasureUnit;
  issues: number[];
  batch_no: string;
  comment: string;
  category: string;
  is_carry_over: boolean;
}

export interface PurchaseCommodity {
  id: string;
  commodity: number;
  quantity: string;
  measure_unit: MeasureUnit;
  total_paid: number;
  unit: string;
  batch_no: string;
  comment: string;
  category: string;
}

export interface IncidentCommodity {
  commodity: number;
  quantity: number;
  category: string;
  delivery_commodity: string;
  batch_no: string;
}

export interface Delivery extends BaseStore {
  model: "delivery";
  delivered_at: string;
  waybill_no: string;
  commodities: DeliveryCommodity[];
  is_initial_stock: boolean;
  is_negative_stock_rebalance: boolean;
}

export interface TakeHomeRation extends BaseStore {
  model: "delivery";
  delivered_at: string;
  waybill_no: string;
  commodities: DeliveryCommodity[];
}

export interface Purchase extends BaseStore {
  model: "purchasedetail";
  purchased_at: string;
  commodities: PurchaseCommodity[];
}

export interface EnrolmentUpdateLevel {
  title: string;
  level: string;
  male_newcomers: number;
  female_newcomers: number;
  male_dropouts: number;
  female_dropouts: number;
}

export interface EnrolmentUpdate extends BaseStore {
  model: "enrolment";
  occurred_on: string;
  levels: EnrolmentUpdateLevel[];
  comment: string;
  school_year: number;
}

export interface OfflineUser extends BaseStore {
  model: "offlineuser";
  first_name: string;
  last_name: string;
  passcode: string;
  role_id: number;
  online_user_id: number;
}

export interface Incident extends BaseStore {
  model: "incident";
  occurred_at: string;
  causes: number[];
  other_cause: string;
  commodities: IncidentCommodity[];
  reason: string;
  is_initial_stock_incident: boolean;
  batch_no: string;
}

export interface SchoolYearLevel {
  title: string;
  level: string;
  description: string;
  initial_male: number;
  initial_female: number;
  custom_name: string;
}

export interface SchoolYear extends BaseStore {
  model: "year";
  weekdays: boolean[];
  has_morning_classes: boolean;
  has_afternoon_classes: boolean;
  has_same_students_in_both_shifts: boolean;
  levels: SchoolYearLevel[];
  starts_on: string;
  ends_on: string;
}

export type AggregateSchoolYearLevel = SchoolYearLevel | EnrolmentUpdateLevel;

// Report Section

export interface ReportAbsenceRow {
  male: number;
  female: number;
  level: string;
  total: number;
}

export interface ReportAbsenceTotalsRow {
  male: number;
  female: number;
  name: "pre" | "primary" | "total";
  total: number;
}

export interface ReportEnrolmentRow {
  male: number;
  female: number;
  level: string;
  total: number;
}

export interface ReportEnrolmentTotalsRow {
  male: number;
  female: number;
  name: "pre" | "primary" | "total";
  total: number;
}

export interface MeasureUnit {
  id: number;
  name: string;
  symbol: string;
  decimals: number;
}

export interface ReportCommodity {
  id: number;
  name: string;
  measure_unit: MeasureUnit;
}

export interface ReportStockMovementsRow {
  qty_lost: string;
  qty_final: string;
  batch_nos: Array<string>;
  commodity: number;
  qty_initial: string;
  qty_received: string;
  qty_distributed: string;
  qty_returned: string;
}

export interface ReportStockMovementsTotals {
  name: "total";
  measure_unit: MeasureUnit;
  qty_lost: string;
  qty_final: string;
  qty_initial: string;
  qty_received: string;
  qty_distributed: string;
  qty_returned: string;
}

export interface ReportPurchaseStockMovementsRow {
  qty_lost: string;
  qty_final: string;
  commodity: number;
  qty_initial: string;
  qty_received: string;
  qty_distributed: string;
}

export interface ReportPurchaseStockMovementsTotals {
  name: "total";
  measure_unit: MeasureUnit;
  qty_lost: string;
  qty_final: string;
  qty_initial: string;
  qty_received: string;
  qty_distributed: string;
}

export interface ReportFoodExpenditure {
  item: number;
  quantity: string;
  total_paid: string;
  commodity: number;
  date: string;
  comments: string;
}

export interface ReportFoodExpenditureTotals {
  name: "total";
  quantity: string;
  total_paid: string;
  measure_unit: MeasureUnit;
}

export interface ReportSchoolDaySummaryRowAttendanceSummary {
  male: number;
  female: number;
}

export interface ReportSchoolDaySummaryRow {
  date: string;
  is_school_day: boolean;
  is_consumption_day: boolean;
  attendance_per_kind: ReportSchoolDaySummaryRowAttendanceSummary;
  student_attendance_per_kind: ReportSchoolDaySummaryRowAttendanceSummary;
  consumption_per_commodity: Record<string, string>;
}

// This should match with wfp/apps/schools/types.py
export interface ReportAggregates {
  absence_rows: ReportAbsenceRow[];
  commodities: ReportCommodity[];
  absence_totals_rows: ReportAbsenceTotalsRow[];
  closest_wfp_office: string;
  enrolment_rows: ReportEnrolmentRow[];
  enrolment_totals_rows: ReportEnrolmentTotalsRow[];
  latest_delivery_date: string;
  local_education_authority: string;
  programme_manager: string;
  regional_education_authority: string;
  school_day_summary_rows: ReportSchoolDaySummaryRow[];
  school_day_summary_totals_row: {
    school_days: number;
    consumption_days: number;
    attendance_per_kind: ReportSchoolDaySummaryRowAttendanceSummary;
    student_attendance_per_kind: ReportSchoolDaySummaryRowAttendanceSummary;
    consumption_per_commodity: Record<string, string>;
  };
  stock_movement_rows_delivery: ReportStockMovementsRow[];
  stock_movement_totals_row_delivery: ReportStockMovementsTotals;
  stock_movement_rows_takehomeration: ReportStockMovementsRow[];
  stock_movement_totals_row_takehomeration: ReportStockMovementsTotals;
  stock_movement_rows_purchasedetail: ReportPurchaseStockMovementsRow[];
  stock_movement_totals_row_purchasedetail: ReportPurchaseStockMovementsTotals;
  food_expenditure_rows: ReportFoodExpenditure[];
  food_expenditure_totals_row: ReportFoodExpenditureTotals;
}

export type ReportState =
  | "open"
  | "closed"
  | "submitted"
  | "approved"
  | "rejected";

export type ReportAction =
  | "update"
  | "close"
  | "submit"
  | "approve"
  | "reject"
  | "amend"
  | "reopen"
  | "reopen_approved"
  | "reopen_validated";

export interface Report extends BaseStore {
  //id: number;
  model: "report";
  aggregates: ReportAggregates;
  comments: string | null;
  date_created: string;
  date_updated: string;
  excel?: string | null;
  is_editable: boolean;
  /** FE-only flag */
  isPreview?: boolean;
  negative_stock: boolean;
  log_entries: ReportLogEntry[];
  month: number;
  pdf?: string | null;
  school_name: string;
  school_year_name: string;
  school_year: number;
  state: ReportState;
  submitted_at: string | null;
  submitter_signature: string;
  year: number;
  start_day: number;
  end_day: number;
}

export interface ReportLogEntry {
  comments: string;
  user: string;
  timestamp: string;
  transition: string;
}

// End Report Section

export type Store =
  | Attendance
  | Delivery
  | TakeHomeRation
  | Purchase
  | EnrolmentUpdate
  | Incident
  | OfflineUser
  | SchoolYear
  | Report;

export interface UserStores {
  [key: number]: Store[];
}

export interface AttendanceStore {
  occurredOn: string;
  mealAttendance: Store | undefined;
  studentAttendance: Store | undefined;
  thrAttendance: Store | undefined;
}

export interface AttendanceStores {
  [occurredOn: string]: AttendanceStore;
}

export interface StoresState {
  changes: number;
  fetching: boolean;
  error: any;
  stores: Store[];
  userStores: UserStores;
  attendanceStores: AttendanceStores;
  storesPreEdit: Store[];
  POSTedStores: string[];
}

export interface ActionState {
  type?: any;
  data?: any;
  client_id?: any;
  stores?: any;
  error?: any;
  response?: any;
  schoolId?: any;
  school?: any;
  user?: any;
}

export const initialState: StoresState = {
  changes: 0,
  fetching: false,
  error: null,
  /** Stores as they currently are in the client, including local edits */
  stores: [],
  /** User stores grouped by school id, to allow offline users to switch between schools */
  userStores: {},
  /** Attendance preprocessed stores that are grouped by accored on date */
  attendanceStores: {},
  /** Stores at the time of the last sync operation */
  storesPreEdit: [],
  POSTedStores: [],
};

const updateAttendanceStore = (
  attendanceStore: AttendanceStore,
  store: Store
) => {
  if (store.model === "attendance") {
    if (store.category === deliveryCategory) {
      attendanceStore!.mealAttendance = store;
    } else if (store.category === studentAttendanceCategory) {
      attendanceStore!.studentAttendance = store;
    } else if (store.category === takeHomeRationCategory) {
      attendanceStore!.thrAttendance = store;
    }
  }
  return attendanceStore;
};

const makeDefaultAttendanceStore = (occurredOn: string): AttendanceStore => ({
  occurredOn,
  mealAttendance: undefined,
  studentAttendance: undefined,
  thrAttendance: undefined,
});

const makeAttendanceStores = (allStores: Store[]) => {
  const attendanceStores: AttendanceStores = {};

  for (let store of allStores) {
    if (store.model === "attendance") {
      const occurredOn: string = store.occurred_on as string;
      const defaultAttendanceStore = makeDefaultAttendanceStore(occurredOn);

      const attendanceStore = updateAttendanceStore(
        attendanceStores[occurredOn] || defaultAttendanceStore,
        store
      );

      attendanceStores[occurredOn] = attendanceStore;
    }
  }

  return attendanceStores;
};

const reducer = (state = initialState, action: ActionState): StoresState => {
  switch (action.type) {
    case CLEAR_STORES:
      // Don't clear userStores (as it is used for offline capability)
      return { ...initialState, userStores: state.userStores };

    case REQUEST:
      return { ...state, fetching: true, error: null };

    case SUCCESS:
      let updatedStores = action.stores;
      const { user, schoolId } = action;
      // In the case where there are still some unsynced changes in the userStores for
      // the given school id, use these stores instead of the ones coming form the backend
      // This only applies to admin users with the offline capability
      if (getCanUseOfflineMode(user)) {
        const userSchoolStores = (state.userStores || {})[schoolId];
        const unsyncedUserSchoolStores = filterUnsyncedStores(userSchoolStores);

        if (unsyncedUserSchoolStores.length > 0) {
          updatedStores = userSchoolStores;
        }
      }

      return {
        ...state,
        fetching: false,
        error: null,
        stores: updatedStores,
        storesPreEdit: updatedStores,
        attendanceStores: makeAttendanceStores(updatedStores),
      };

    case FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
      };

    case UPDATE:
      const { id, model, values, type, section, category } = action.data;
      /** Index of the provided store (action.data) if updated, -1 if created */
      const preexistentStoreIndex = state.stores.findIndex(
        (store: any) => store.client_id === id
      );

      const isUpdate = preexistentStoreIndex !== -1;

      if (
        ("occurred_on" in values &&
          moment(values.occurred_on).isBefore(
            moment().format("YYYY-MM-DD"),
            "month"
          )) ||
        ("occurred_at" in values &&
          moment(values.occurred_at).isBefore(
            moment().format("YYYY-MM-DD"),
            "month"
          )) ||
        ("delivered_at" in values &&
          moment(values.delivered_at).isBefore(
            moment().format("YYYY-MM-DD"),
            "month"
          )) ||
        ("purchased_at" in values &&
          moment(values.purchased_at).isBefore(
            moment().format("YYYY-MM-DD"),
            "month"
          )) ||
        ("starts_on" in values &&
          moment(values.starts_on).isBefore(
            moment().format("YYYY-MM-DD"),
            "month"
          ))
      ) {
        toast.warning(
          toastFormattedMessages.find(
            (e) => e.name === "toast.dataEnteredForPreviousMonths"
          )?.label,
          {
            toastId: "previousMonthsSyncs",
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
          }
        );
      }

      /**
       * When "deleting" individual attendance or consumption we can just update them to nothing.
       * it is not a good idea to try and delete individual objects from the "dailyReport" object.
       * This is why we switch type from "delete" to "update" in these situations
       */
      let newValues = { ...values };
      let newType = type;
      if (type === "delete" && category !== takeHomeRationCategory) {
        if (section === "attendance") {
          delete newValues.levels;
          newType = "update";
        } else if (section === "consumption") {
          delete newValues.consumption;
          newType = "update";
        }
      } else if (
        type === "update" &&
        section !== undefined &&
        values.type === "delete"
      ) {
        // When creating a section while the other one is deleted (but not synced)
        // we need to create the new object without the deleted section
        if (section === "attendance") {
          delete newValues.consumption;
        } else if (section === "consumption") {
          delete newValues.levels;
        }
      }

      // copy attendance stores
      const newAttendanceStores = JSON.parse(
        JSON.stringify(state.attendanceStores)
      );

      const newStore = {
        ...newValues,
        // Preserve `id`: the BE DB id.
        // BE needs it to discern between update / create when syncing
        id: isUpdate ? state.stores[preexistentStoreIndex].id : undefined,
        last_edit: moment().toISOString(),
        client_id: id, // FE-generated id
        build_number: buildNumber,
        model,
        sync: false,
        type: newType,
        category,
      };

      // Create a new stores array
      // (never mutate redux state in-place unless you want to break reactivity)
      let newStoresArray: Store[];
      if (isUpdate) {
        if (
          state.stores[preexistentStoreIndex].sync === false &&
          newType === "delete" &&
          preexistentStoreIndex === -1
        ) {
          newStoresArray = [...state.stores].filter(
            (_, index) => index !== preexistentStoreIndex
          );
        } else {
          newStoresArray = [...state.stores];
          newStoresArray[preexistentStoreIndex] = newStore;
        }
      } else {
        newStoresArray = [...state.stores, newStore];
      }

      // If model is an attendance model update

      const occurredOn = newStore.occurred_on;
      //Add current store to newAttendanceStores;
      const attendanceStore: AttendanceStore =
        newAttendanceStores[occurredOn] ||
        makeDefaultAttendanceStore(occurredOn);
      newAttendanceStores[occurredOn] = updateAttendanceStore(
        attendanceStore,
        newStore
      );

      const userStoresChanged = getUserStoresChanged(
        state,
        newStoresArray,
        action
      );

      return {
        ...state,
        changes: state.changes + 1,
        stores: newStoresArray,
        attendanceStores: newAttendanceStores,
        ...userStoresChanged,
      };

    case POST_REQUEST: {
      return {
        ...state,
        fetching: true,
        error: null,
        POSTedStores: action.stores.map((store: Store) => store.client_id), // We'll need them to parse the response
      };
    }

    case POST_REQUEST_CHUNK: {
      return {
        ...state,
        fetching: true,
        error: null,
        POSTedStores: action.stores.map((store: Store) => store.client_id), // We'll need them to parse the response
      };
    }

    case POST_SUCCESS:
      // Create copies to preserve immutability
      const stores = [...state.stores];
      const storesPreEdit = [...(state.storesPreEdit || [])];

      // Key concept: the response preserves the order of stores of the request.
      // so we iterate over the response's data,
      // knowing that response.data.data[i] is the same syncable as POSTedStores[i].
      action.response.data.data.forEach(
        (
          respStore: { error: any; id: string; error_details: any },
          i: number
        ) => {
          const preexistentStoreIndex: any = stores.findIndex(
            (store: Store) => store.client_id === state.POSTedStores[i]
          );

          // If this store was successfully synced
          if (!respStore.error) {
            // Drop this store's locally stored errors
            const { error, errorDetails, ...shallowCopy } = stores[
              preexistentStoreIndex
            ];

            // Update this store (TODO: use all of the response, not just `id`?)
            const updatedStore = {
              ...shallowCopy,
              id: respStore.id,
              sync: true,
              error: undefined,
              errorDetails: undefined,
            };
            stores[preexistentStoreIndex] = updatedStore;

            // Also update this store in storesPreEdit
            const indexInPreEdit = storesPreEdit.findIndex(
              (store) => store.client_id === shallowCopy.client_id
            );
            if (indexInPreEdit === -1) {
              storesPreEdit.push(updatedStore);
            } else {
              storesPreEdit[indexInPreEdit] = updatedStore;
            }
          } else {
            // Add errors from the BE to this store
            stores[preexistentStoreIndex] = {
              ...stores[preexistentStoreIndex],
              error: respStore.error,
              errorDetails: respStore.error_details,
            };
          }
        }
      );

      const newUserStores = getUserStoresChanged(state, stores, action);

      return {
        ...state,
        fetching: false,
        error: null,
        stores,
        storesPreEdit,
        ...newUserStores,
      };

    case POST_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
      };

    case RESET_SYNC:
      const { client_id } = action;
      const indexToDrop = state.stores.findIndex(
        (store: any) => store.client_id === client_id
      );
      if (indexToDrop === -1) {
        // The store you wanted to reset doesn't exist
        return state;
      }

      // Keep all stores except the one to reset
      const storesAfterDropping = state.stores.filter(
        (store: any) => store.client_id !== client_id
      );
      const resettedStore = (state.storesPreEdit || []).find(
        (store: any) => store.client_id === client_id
      );
      if (resettedStore) {
        // Re-add the dropped store as it was just after syncing
        storesAfterDropping.push(resettedStore);
      }

      const userStoresUpdated = getUserStoresChanged(
        state,
        storesAfterDropping,
        action
      );

      return {
        ...state,
        stores: storesAfterDropping,
        attendanceStores: makeAttendanceStores(storesAfterDropping),
        ...userStoresUpdated,
      };

    case USER_STORES_REQUEST:
      return { ...state, fetching: true, error: null };

    case USER_STORES_SUCCESS:
      // We only need to overwrite the user stores that don't have any unsynced items
      let filteredUserStores: UserStores = {};
      Object.keys(state.userStores || {}).forEach((key: any) => {
        const keyUserStores = state.userStores[key];
        if (filterUnsyncedStores(keyUserStores).length > 0) {
          filteredUserStores[key] = keyUserStores;
        }
      });

      return {
        ...state,
        fetching: false,
        error: null,
        userStores: { ...action.stores, ...filteredUserStores },
      };

    case USER_STORES_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.error,
      };
    case SET_USER_CACHED_STORES:
      const cachedStores = [...(state.userStores[action.schoolId] || [])];
      return {
        ...state,
        stores: cachedStores,
        attendanceStores: makeAttendanceStores(cachedStores),
      };
    default:
      return state;
  }
};

const persistedReducer = persistReducer(
  {
    key: "stores",
    storage,
    blacklist: ["changes", "error", "fetching"],
  },
  reducer
);

export default persistedReducer;

export function* storesSaga() {
  yield takeEvery(REQUEST, workerGetStoresSaga);
}

function fetchGetStores(schoolId: number | string) {
  const url = `${process.env.REACT_APP_API_URL}/schools/${schoolId}/sync`;
  return axios({
    method: "GET",
    url: url,
  });
}

function* workerGetStoresSaga(action: {
  schoolId: number | string;
  type: typeof REQUEST;
}): any {
  try {
    const response = yield call(fetchGetStores, action.schoolId);

    const stores: Store[] = response.data.data.map(
      (store: { data: any; id: any; model: any }) => {
        // Transform the response's stores into a flatter shape for the FE
        const storeObject = getReshapedStore(store);
        return storeObject;
      }
    );

    yield put({ type: SUCCESS, stores, schoolId: action.schoolId });
  } catch (error) {
    yield put({ type: FAILURE, error });
  }
}

export function* storesPOSTWatcher() {
  yield takeEvery(POST_REQUEST, storesPOSTWorker);
}

/**
 * Reshapes stores to fit BE serializer, performs POST
 *
 * Reshaping drops FE-only metadata:
 * - sync
 * - client_id
 * - error
 * - errorDetails
 *
 * Reshaping moves all other sync data within `data`, except for `model` and `id`.
 */
function storesPOSTRequest({
  stores,
  schoolId,
}: {
  stores: Store[];
  schoolId: number;
}) {
  const reshapedStores = stores.map(
    ({
      client_id,
      sync, // Dropped
      id,
      model,
      error, // Dropped
      errorDetails, // Dropped
      type,
      section,
      ...otherStoreData
    }) => {
      return id
        ? {
            model,
            id,
            data: otherStoreData,
            type,
            section,
          }
        : {
            model,
            client_id,
            data: otherStoreData,
            type,
            section,
          };
    }
  );

  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/schools/${schoolId}/sync`,
    data: { data: reshapedStores },
  });
}

function getUnsyncedStoresChunks(
  arr: Store[],
  bulkSize: number = 5
): Store[][] {
  const bulks: Store[][] = [];
  for (let i = 0; i < Math.ceil(arr.length / bulkSize); i++) {
    bulks.push(arr.slice(i * bulkSize, (i + 1) * bulkSize));
  }
  return bulks;
}

function* storesPOSTWorker(action: {
  stores: Store[];
  schoolId: any;
  type: typeof POST_REQUEST;
}): any {
  const unsyncedStoresChunks = getUnsyncedStoresChunks(
    sortModels(action.stores)
  );
  const perSyncableErrors = [];
  try {
    for (let i = 0; i < unsyncedStoresChunks.length; i++) {
      yield put({
        stores: unsyncedStoresChunks[i],
        schoolId: action.schoolId,
        type: POST_REQUEST_CHUNK,
      });
      const response = yield call(storesPOSTRequest, {
        stores: unsyncedStoresChunks[i],
        schoolId: action.schoolId,
      });
      yield put({ type: POST_SUCCESS, response });

      const perSyncableError = response.data.data.filter(
        (datum: { error?: any }) => {
          return Object.keys(datum).includes("error");
        }
      );
      perSyncableErrors.push(perSyncableError);
    }
    // If nothing is wrong with the recently POSTed syncables,
    // refetch all syncables from the BE (The BE may have added/removed data)
    if (Object.values(perSyncableErrors).every((x) => !x.length)) {
      yield put(GETStores(action.schoolId));
    }
  } catch (error) {
    yield put({ type: POST_FAILURE, error });
  }
  // After POSTing syncables, refetch other School Connect variables
  yield all([
    // Refetch School Connect primitives (Admins may have updated them)
    put(commoditiesDuck.fetchList()),
    put(incidentCausesDuck.fetchList()),
    put(deliveryIssuesDuck.fetchList()),
    // Refetch reports (The BE generates them based on syncables)
    put(reportsDuck.fetchList("DEFAULT", { school: action.schoolId })),
  ]);
}

export function* updateStoreSagaWatcher() {
  yield takeEvery(UPDATE, updateStoreSagaWorker);
}

function* updateStoreSagaWorker() {
  // Saga worker to update the last performed action attribute of the auth store
  yield put({
    type: REFRESH_LAST_ACTION_PERFORMED_AT,
    data: moment().toString(),
  });
}

export function* userStoresSagaWatcher() {
  yield takeLatest(USER_STORES_REQUEST, userStoresSagaWorker);
}

function fetchUserStores() {
  const url = `${process.env.REACT_APP_API_URL}/schools/user/sync`;
  return axios({
    method: "GET",
    url: url,
  });
}

function* userStoresSagaWorker(): any {
  try {
    const response = yield call(fetchUserStores);
    let stores: { [key: number]: Store[] } = {};
    response.data.data.forEach(
      (store: { data: any; id: number; model: string; school: number }) => {
        // Transform the response's stores into a flatter shape for the FE
        const { school } = store;
        const storeObject = getReshapedStore(store);
        if (!stores[school]) {
          stores[school] = [storeObject];
        } else {
          stores[school].push(storeObject);
        }
      }
    );
    yield put({ type: USER_STORES_SUCCESS, stores });
  } catch (error) {
    yield put({ type: USER_STORES_FAILURE, error });
  }
}

//
// ACTION CREATORS
//

/** Requests all of the provided school's stores from the BE */
export const GETStores = (schoolId: any) => ({ type: REQUEST, schoolId });

/** POSTs the provided `stores` to the BE */
export const POSTStores = (stores: Store[], schoolId: any) => ({
  type: POST_REQUEST,
  stores,
  schoolId,
});

/** Drops all stores */
export const clearStores = () => ({
  type: CLEAR_STORES,
});

/** Resets a store to its state as of its latest sync (drops it if never synced) */
export const resetStore = (client_id: string) => ({
  type: RESET_SYNC,
  client_id,
});

/**
 * Updates or creates a syncable.
 *
 * Generates an UUIDv4 `client_id` for it if not provided.
 */
export const updateStore = ({
  id,
  model,
  values,
  type,
  section,
  category,
}: {
  id: string | undefined;
  model: StoreModel; // add storeModel
  values: any;
  type: string;
  section: string;
  category: string;
}) => ({
  type: UPDATE,
  data: {
    id: id || v4(),
    values,
    model,
    type,
    section,
    category,
  },
});

/** Requests the stores for all of the user's schools */
export const requestUserStores = () => ({ type: USER_STORES_REQUEST });

export const setUserCachedStores = (schoolId: number) => ({
  type: SET_USER_CACHED_STORES,
  schoolId,
});

//
// SELECTORS
//

export const getIsStoresFetching = (state: RootState) => state.stores.fetching;

export const getStoresError = (state: RootState) => state.stores.error;

/** Returns all stores (School Connect's "syncable stuff") */
export const getAllStores = (state: RootState) => state?.stores?.stores;

/** Returns all unsynced stores (School Connect's "syncable stuff") */
export const getUnsyncedStores = (state: RootState) =>
  filterUnsyncedStores(getAllStores(state));

/** Returns a selector */
export const getStoreByClientId = (clientId: string) => (state: RootState) =>
  getAllStores(state).find((store: Store) => store.client_id === clientId) ||
  null;

/** Returns a selector */
export const getDeliveryStoreByClientId = (clientId: string) => (
  state: RootState
) =>
  getAllStores(state).find((store: Store) => store.client_id === clientId) ||
  null;

export const getAllAttendances = (state: RootState): Attendance[] =>
  (getAllStores(state)?.filter(
    (store: Store) => store.model === "attendance"
  ) as Attendance[]) || null;

export const getAttendancesByDateRange = (date0: string, date1: string) => (
  state: RootState
) =>
  getAllAttendances(state).filter((store: { occurred_on: string }) =>
    moment(store.occurred_on).isBetween(date0, date1, "day", "[]")
  );

//TO-DO: Need to cover "mixed"
export const getAllDeliveries = (state: RootState) =>
  getAllStores(state).filter(
    (store: Store) =>
      store.model === "delivery" &&
      store.type !== "delete" &&
      store.category !== "takehomeration"
  ) as Delivery[];

export const getAllTakeHomeRationsDeliveries = (state: RootState) =>
  getAllStores(state).filter(
    (store: Store) =>
      store.model === "delivery" && store.category !== "delivery"
  ) as TakeHomeRation[];

export const getDeliveryAttendancesByDateRange = (
  date0: string,
  date1: string
) => (state: RootState) =>
  getAttendanceStores(state).filter((store: { occurred_on: string }) =>
    moment(store.occurred_on).isBetween(date0, date1, "day", "[]")
  );

export const getStudentAttendancesByDateRange = (
  date0: string,
  date1: string
) => (state: RootState) =>
  getStudentAttendanceStores(state).filter((store: { occurred_on: string }) =>
    moment(store.occurred_on).isBetween(date0, date1, "day", "[]")
  );

export const getAttendanceStores = (state: RootState) =>
  getAllAttendances(state).filter(
    (store: { category: string }) => store.category === deliveryCategory
  ) as Attendance[];

export const getStudentAttendanceStores = (state: RootState) =>
  getAllAttendances(state).filter(
    (store: { category: string }) =>
      store.category === studentAttendanceCategory
  ) as Attendance[];

export const getThrAttendanceStores = (state: RootState) =>
  getAllAttendances(state).filter(
    (store: { category: string }) => store.category === takeHomeRationCategory
  ) as Attendance[];

export const getThrAttendanceStoresByDate = (date: string) => (
  state: RootState
) =>
  getAllAttendances(state).filter(
    (store: { category: string; occurred_on: string }) =>
      moment(store.occurred_on).isSame(date, "days") &&
      store.category === takeHomeRationCategory
  ) as Attendance[];

export const getThrAttendanceStoresByDateRange = (
  date0: string,
  date1: string
) => (state: RootState) =>
  getThrAttendanceStores(state).filter((store: { occurred_on: string }) =>
    moment(store.occurred_on).isBetween(date0, date1, "day", "[]")
  ) as Attendance[];

export const getAllAttendanceStoresByDay = (
  state: RootState
): AttendanceStores => state.stores.attendanceStores;

export const getAllAttendanceStoresForDay = (date: string) => (
  state: RootState
): AttendanceStore | undefined => state.stores.attendanceStores[date as string];

export const getAllPurchases = (state: RootState) =>
  getAllStores(state).filter(
    (store: Store) =>
      store.model === "purchasedetail" && store.type !== "delete"
  ) as Purchase[];

export const hasPurchaseStore = (state: RootState) =>
  getAllPurchases(state).length > 0 ? true : false;

export const hasTHRStore = (state: RootState) =>
  getAllTakeHomeRationsDeliveries(state).length > 0 ? true : false;

export const checkDeliveryDatetimeIsUniquePerDatetime = (
  datetime: Moment,
  currentStore: Store
) => (state: RootState) => {
  return !getAllStores(state).find(
    (store: Store) =>
      store.model === "delivery" &&
      store.type !== "delete" &&
      moment(store.delivered_at).isSame(datetime) &&
      store.client_id !== currentStore?.client_id
  );
};

export const getAllOfflineUsers = (state: RootState): OfflineUser[] =>
  getAllStores(state).filter(
    (store: Store) => store.model === "offlineuser" && store.type !== "delete"
  ) as OfflineUser[];

export const getOfflineUserByClientId = (id: string) => (state: RootState) =>
  getAllOfflineUsers(state).find((store: Store) => store.client_id === id);

export const getAllIncidents = (state: RootState): Incident[] =>
  getAllStores(state).filter(
    (store: Store) => store.model === "incident"
  ) as Incident[];

export const checkIncidentDateIsUniquePerDate = (
  datetime: string,
  currentStore: Store
) => (state: RootState) => {
  return !getAllStores(state).find(
    (store: Store) =>
      store.model === "incident" &&
      store.type !== "delete" &&
      (store.is_initial_stock_incident === false ||
        !store.is_initial_stock_incident) &&
      moment(store.occurred_at).isSame(datetime) &&
      store.client_id !== currentStore?.client_id
  );
};

export const getAllEnrolmentUpdates = (state: RootState) =>
  getAllStores(state).filter(
    (store: Store) => store.model === "enrolment"
  ) as EnrolmentUpdate[];

export const checkEnrolmentUpdateIsUniquePerDate = (
  date: string,
  currentStore: Store
) => (state: RootState) => {
  return !getAllStores(state).find(
    (store: Store) =>
      store.model === "enrolment" &&
      store.type !== "delete" &&
      moment(store.occurred_on).isSame(date) &&
      store.client_id !== currentStore?.client_id
  );
};

export const getEnrolmentUpdatesByDateRange = (
  date0: string,
  date1: string
) => (state: RootState) =>
  getAllEnrolmentUpdates(state).filter((enrolment) =>
    moment(enrolment.occurred_on).isBetween(date0, date1, "day", "[]")
  );

export const getAllSchoolYears = (state: RootState): SchoolYear[] => {
  return getAllStores(state).filter(
    (store: Store) => store.model === "year"
  ) as SchoolYear[];
};

export const getOngoingSchoolYearByDate = (date: string) =>
  createSelector(
    [getAllSchoolYears],
    (schoolYears) =>
      schoolYears.find((schoolYear) =>
        // "[]" argument means "including first and last days"
        moment(date).isBetween(
          schoolYear.starts_on,
          schoolYear.ends_on,
          "days",
          "[]"
        )
      ) || null
  );

export const getSchoolYearById = (schoolYearId: number) =>
  createSelector([getAllSchoolYears], (schoolYears) =>
    schoolYears.find((schoolYear) => schoolYear.object_id === schoolYearId)
  );

export const getEnrolmentUpdatesBySchoolYearId = (schoolYearId: number) =>
  createSelector([getAllEnrolmentUpdates], (allEnrolmentUpdates) =>
    allEnrolmentUpdates.filter(
      (enrolmentUpdate) => enrolmentUpdate.school_year === schoolYearId
    )
  );

export const getSchoolYearLevelsBySchoolYearId = (schoolYearId: number) =>
  createSelector(
    [
      getSchoolYearById(schoolYearId),
      getEnrolmentUpdatesBySchoolYearId(schoolYearId),
    ],
    (schoolYear, enrolmentUpdates) => {
      const levels: {
        [key: string]: AggregateSchoolYearLevel;
      } = {};
      // Add all levels from schoolYear to the result
      if (schoolYear) {
        for (const schoolYearLevel of schoolYear.levels) {
          if (!(schoolYearLevel.level in levels)) {
            levels[schoolYearLevel.level] = schoolYearLevel;
          }
        }
      } else {
        // School Year does not exist just return empty array.
        return Object.values(levels);
      }

      for (const enrolmentUpdate of enrolmentUpdates) {
        for (const enrolmentUpdateLevel of enrolmentUpdate.levels) {
          if (!(enrolmentUpdateLevel.level in levels)) {
            levels[enrolmentUpdateLevel.level] = enrolmentUpdateLevel;
          }
        }
      }
      return Object.values(levels);
    }
  );

/**
 * Returns the "current" (see wiki) SchoolYear store
 */
export const getCurrentSchoolYear = (state: RootState) =>
  getOngoingSchoolYearByDate(moment().format("YYYY-MM-DD"))(state);

export const getPreviousSchoolYears = (state: RootState) => {
  const currentSchoolYear = getCurrentSchoolYear(state);
  const previousYears = getAllSchoolYears(state)
    .sort((a: SchoolYear, b: SchoolYear) =>
      moment(a.starts_on).isAfter(b.starts_on) ? -1 : 1
    )
    .filter((schoolYear: SchoolYear) =>
      moment(schoolYear.ends_on).isBefore(currentSchoolYear?.starts_on)
    );
  return previousYears;
};

export const getCurrentOrLatestSchoolYear = (state: RootState) => {
  // If there is a current school year, return it
  // otherwise, return the latest previous school year or undefined
  const currentSchoolYear = getCurrentSchoolYear(state);
  const previousSchoolYears = getPreviousSchoolYears(state);
  return currentSchoolYear
    ? currentSchoolYear
    : !currentSchoolYear && previousSchoolYears?.length > 0
    ? previousSchoolYears[0]
    : undefined;
};

export const getSchoolYearsByYearAndMonth = (year: number, month: number) => (
  state: RootState
) => {
  const dateToCheck = moment()
    .year(year)
    .month(month - 1)
    .date(1);
  return getAllSchoolYears(state).filter((schoolYear) =>
    dateToCheck.isBetween(
      schoolYear?.starts_on,
      schoolYear?.ends_on,
      "month",
      "[]"
    )
  );
};

export const getSchoolYearByDate = (date: string) => (state: RootState) =>
  getOngoingSchoolYearByDate(date)(state);

/**
 * Returns the "upcoming" (see wiki) SchoolYear store
 *
 * It's the SchoolYear right after the current one.
 */
export const getUpcomingSchoolYear = (state: RootState) => {
  const currentSchoolYear = getCurrentSchoolYear(state);
  const now = moment();
  const upcomingYear = getAllSchoolYears(state)
    .slice()
    .sort((a: SchoolYear, b: SchoolYear) =>
      moment(a.starts_on).isAfter(b.starts_on) ? 1 : -1
    )
    .find((schoolYear: SchoolYear) => {
      const isCurrentSchoolYear =
        currentSchoolYear &&
        currentSchoolYear.client_id === schoolYear.client_id;
      return now.isBefore(schoolYear.starts_on) && !isCurrentSchoolYear;
    });
  return upcomingYear || null;
};

/**
 * Returns EnrolmentUpdate stores within the "current" SchoolYear
 */
export const getCurrentSchoolYearEnrolmentUpdates = createSelector(
  [getCurrentSchoolYear, getAllEnrolmentUpdates],
  (currentSchoolYear, enrolmentUpdates) => {
    if (currentSchoolYear === null) return [];
    return enrolmentUpdates.filter((enrolmentUpdate: { occurred_on: string }) =>
      moment(enrolmentUpdate.occurred_on).isBetween(
        currentSchoolYear.starts_on,
        currentSchoolYear.ends_on,
        "day",
        "[]"
      )
    );
  }
);

/**
 * Returns a Moment instance of when a syncable "happened".
 * In the case of school years, when a school year started.
 */
export const getSyncableMoment = (s: Store): Moment => {
  switch (s.model) {
    case "attendance":
      return moment(s.occurred_on);
    case "delivery":
      return moment(s.delivered_at);
    case "purchasedetail":
      return moment(s.purchased_at);
    case "enrolment":
      return moment(s.occurred_on);
    case "incident":
      return moment(s.occurred_at);
    case "year":
      return moment(s.starts_on);
    case "offlineuser":
      return moment();
    default:
      return moment();
  }
};

/** Function to be used as an argument to sort(). Sorts by decreasing datetime */
export const syncableSortFnDecDateTime = (a: Store, b: Store) =>
  getSyncableMoment(b).valueOf() - getSyncableMoment(a).valueOf();

export const getIsSyncableLocked = (state: RootState, syncable: Store) => {
  if (!syncable) return false;
  const syncableMoment = getSyncableMoment(syncable);
  const report = getReportByMoment(state, syncableMoment);
  return getIsReportLocked(report);
};

export const getIsDateLocked = (
  state: RootState,
  date: Moment | MomentInput
) => {
  if (!date) return false;
  const report = getReportByMoment(state, date);
  return getIsReportLocked(report);
};

export const isAttendanceStoreCompleted = (
  attendanceStore: Attendance,
  studentAttendanceStore: Attendance,
  currentSchoolEnabledStudentAttendance: boolean,
  idToNoMealReasonMapping: IdToNoMealReasonMapping
) => {
  let isCompleted = false;

  const hasNoMealReason =
    (attendanceStore?.consumption?.no_meal_reasons || []).length > 0;

  const isNoSchoolDay =
    hasNoMealReason &&
    !!attendanceStore?.consumption?.no_meal_reasons?.some(
      (rId) => idToNoMealReasonMapping[rId]?.is_no_school_day
    );

  const isNoMealDay = hasNoMealReason && !isNoSchoolDay;

  const hasConsumption = !!attendanceStore?.consumption?.meal_provided;

  const hasMealAttendance = (attendanceStore.levels || []).length > 0;

  if (currentSchoolEnabledStudentAttendance) {
    // Checks when student attendance is enabled
    // This is reversed so I would not need to implement seperate studentattendanceEnable=False cases.
    const missingStudentAttendance = studentAttendanceStore === undefined;

    // Calculate complete days:
    const isFullyComplete =
      !hasNoMealReason &&
      hasConsumption &&
      hasMealAttendance &&
      !missingStudentAttendance;

    const isNoSchoolDayComplete =
      isNoSchoolDay &&
      !hasConsumption &&
      !hasMealAttendance &&
      missingStudentAttendance;

    const isNoMealDayComplete =
      isNoMealDay &&
      !hasConsumption &&
      !hasMealAttendance &&
      !missingStudentAttendance;

    isCompleted =
      isFullyComplete || isNoSchoolDayComplete || isNoMealDayComplete;
  } else {
    // Normal flow checks
    const isFullyComplete =
      !hasNoMealReason && hasConsumption && hasMealAttendance;

    const isNoSchoolDayComplete =
      isNoSchoolDay && !hasConsumption && !hasMealAttendance;

    const isNoMealDayComplete =
      isNoMealDay && !hasConsumption && hasMealAttendance;

    isCompleted =
      isFullyComplete || isNoSchoolDayComplete || isNoMealDayComplete;
  }

  return isCompleted;
};

export const sidebarStyling = {
  complete: { backgroundColor: "#c1eed099" },
  incomplete: {
    backgroundColor: "#f6e6e9",
  },
};

export const attendanceStoreSidebarItemStyling = (
  attendanceStore: Attendance,
  studentAttendanceStore: Attendance,
  currentSchoolEnabledStudentAttendance: boolean,
  idToNoMealReasonMapping: IdToNoMealReasonMapping
) => {
  if (attendanceStore?.model === "attendance") {
    if (attendanceStore?.category === deliveryCategory) {
      return isAttendanceStoreCompleted(
        attendanceStore,
        studentAttendanceStore,
        currentSchoolEnabledStudentAttendance,
        idToNoMealReasonMapping
      )
        ? sidebarStyling.complete
        : sidebarStyling.incomplete;
    } else if (!attendanceStore?.category) {
      // if model === attendance and there is no category,
      // it seems to indicate that there is no meal or attendance on
      // that particular date.
      return sidebarStyling.incomplete;
    } else {
      // If it is takehome ration or student attendance just show it as green.
      return sidebarStyling.complete;
    }
  } else {
    return {};
  }
};

//
// UTILS
//

const filterUnsyncedStores = (stores: Store[] = []) =>
  stores.filter((store: { sync: boolean }) => store.sync !== true);

const getReshapedStore = (store: {
  data: any;
  id: number;
  model: string;
  school?: number;
}) => {
  const { data, id, model } = store;
  const { id: object_id } = data;
  const storeObject = {
    ...data,
    object_id,
    id, // N.B.: this is the `Sync` instance id, NOT the child instance's id
    model,
    sync: true,
    build_number: buildNumber,
    client_id: v4(), // Generate `client_id` to identify this syncable in the FE
  };
  return storeObject;
};

const getUserStoresChanged = (
  state: StoresState,
  newStoresArray: Store[],
  action: ActionState
) => {
  // Logic for handling the persistance of the changes made by offline admin
  // users accross multiple schools.

  const { user, school } = action;

  // At the moment it will only work if the userStores is already populated
  // (i.e. an admin with offline capability logs in online)
  let userStoresChanged: { userStores?: UserStores } = {};
  const userStoresKeys = Object.keys(state.userStores || {});
  if (school?.id && getCanUseOfflineMode(user) && userStoresKeys.length) {
    userStoresChanged = {
      userStores: { ...state.userStores, [school.id]: newStoresArray },
    };
  }
  return userStoresChanged;
};
