import React from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";

import {
  buildAttendanceUrl,
  getFilteredAttendanceTotal,
} from "helpers/attendance";
import CommodityShowSmall from "components/CommodityShowSmall";
import SidebarItem from "components/SidebarItem";
import { AttendanceItemProps } from "./types";
import { getModelDateFormat } from "components/UneditableMessage";
import { useSelector } from "react-redux";
import { getIdToNoMealReasonMapping } from "data-handler/ducks/noMealReasons";

type MealAttendanceItemParams = {
  item: string;
  schoolId: string;
};

const MealAttendanceItem: React.ComponentType<AttendanceItemProps> = ({
  currentStoreData,
  store,
  studentStore,
  thrStore,
  currentSchoolEnabledStudentAttendance,
  ...extraProps
}) => {
  const params: MealAttendanceItemParams = useParams();

  // Date from one of the available dates.
  const storeDate: string =
    getModelDateFormat(store) ||
    getModelDateFormat(studentStore) ||
    getModelDateFormat(thrStore) ||
    getModelDateFormat(currentStoreData);

  const idToNoMealReasonMapping = useSelector(getIdToNoMealReasonMapping);

  const selected =
    (currentStoreData && currentStoreData.client_id === store.client_id) ||
    (currentStoreData === undefined && store.client_id === params.item);

  const meal_provided = store.consumption && store.consumption.meal_provided;

  const hasNoMealReason =
    (store?.consumption?.no_meal_reasons || []).length > 0;

  const isNoSchoolDay =
    hasNoMealReason &&
    store?.consumption?.no_meal_reasons?.some(
      (rId) => idToNoMealReasonMapping[rId].is_no_school_day
    );

  const isNoMealDay = hasNoMealReason && !isNoSchoolDay;

  const hasMealAttendance = (store.levels || []).length > 0;
  const hasConsumption = !!store.consumption?.meal_provided;
  const hasStudentAttendance = !!studentStore;

  let subTitle: any;
  if (store.levels) {
    subTitle = (
      <>
        {getFilteredAttendanceTotal({
          attendance: store,
          kinds: ["male"],
        })}{" "}
        <FormattedMessage id="app.boys" defaultMessage="boys" />,{" "}
        {getFilteredAttendanceTotal({
          attendance: store,
          kinds: ["female"],
        })}{" "}
        <FormattedMessage id="app.girls" defaultMessage="girls" />
      </>
    );
  } else {
    subTitle = (
      <>
        <FormattedMessage
          id="AttendanceItem.noAttendanceAdded"
          defaultMessage="No attendance added"
        />
      </>
    );
  }

  const subTitleNoClass = store?.consumption?.no_meal_reasons?.map(
    (reasonId: number) => idToNoMealReasonMapping[reasonId]?.name
  );

  const missingAttendanceWithoutStudentAttendance =
    !currentSchoolEnabledStudentAttendance &&
    !hasNoMealReason &&
    !hasMealAttendance;

  const missingAttendanceWithStudentAttendance =
    currentSchoolEnabledStudentAttendance &&
    !hasNoMealReason &&
    !(hasMealAttendance && hasStudentAttendance);

  const missingAttendanceWithStudentAttendanceOnNoMealDay =
    currentSchoolEnabledStudentAttendance &&
    isNoMealDay &&
    !hasStudentAttendance;

  const missingAttendance =
    missingAttendanceWithoutStudentAttendance ||
    missingAttendanceWithStudentAttendance ||
    missingAttendanceWithStudentAttendanceOnNoMealDay;

  const missingMeal = !hasNoMealReason && !hasConsumption;

  let highlightTitle: React.ReactElement | null = null;
  let extraTitle: React.ReactElement | null = null;

  if (missingAttendance && missingMeal) {
    highlightTitle = (
      <>
        <FormattedMessage
          id="AttendanceItem.provide"
          defaultMessage="Provide"
        />{" "}
        <FormattedMessage
          id="AttendanceItem.attendance"
          defaultMessage="attendance"
        />
        {", "}
        <FormattedMessage id="AttendanceItem.meals" defaultMessage="meals" />
      </>
    );
  } else if (missingAttendance) {
    highlightTitle = (
      <>
        <FormattedMessage
          id="AttendanceItem.provide"
          defaultMessage="Provide"
        />{" "}
        <FormattedMessage
          id="AttendanceItem.attendance"
          defaultMessage="attendance"
        />
      </>
    );
  } else if (missingMeal) {
    highlightTitle = (
      <>
        <FormattedMessage
          id="AttendanceItem.provide"
          defaultMessage="Provide"
        />{" "}
        <FormattedMessage id="AttendanceItem.meals" defaultMessage="meals" />
      </>
    );
  } else {
    let availableStore = currentSchoolEnabledStudentAttendance
      ? studentStore
      : store;
    //let availableStore = store || studentStore;
    extraTitle = (
      <>
        {getFilteredAttendanceTotal({
          attendance: availableStore,
        })}{" "}
        <FormattedMessage id="app.students" defaultMessage="students" />
      </>
    );
  }

  const noClassTitle = (
    <FormattedMessage
      id="AttendanceItem.noClassMessage"
      defaultMessage="No Class"
    />
  );

  const thrTitle: React.ReactElement | null = thrStore ? (
    <FormattedMessage
      id="AttendanceItem.thrInformationDistributed"
      defaultMessage="Take-home ration information distributed"
    />
  ) : null;

  let comment = null;

  if (hasNoMealReason) {
    comment = <FormattedMessage id="app.noClassMealInfo" defaultMessage=" " />;
  } else if (hasConsumption && !hasNoMealReason) {
    comment = (
      <div>
        <FormattedMessage id="app.mealProvided" defaultMessage="Meal served" />
      </div>
    );
  } else if (!hasConsumption && !hasNoMealReason) {
    comment = (
      <FormattedMessage
        id="app.noMealProvided"
        defaultMessage="No meal served"
      />
    );
  } else {
    comment = (
      <FormattedMessage id="app.noMealInfo" defaultMessage="No meal info" />
    );
  }

  return (
    <SidebarItem
      {...extraProps}
      store={store}
      studentStore={studentStore}
      thrStore={thrStore}
      currentSchoolEnabledStudentAttendance={
        currentSchoolEnabledStudentAttendance
      }
      selected={selected}
      subTitle={isNoSchoolDay ? subTitleNoClass : subTitle}
      thrTitle={thrTitle}
      id={store.client_id}
      key={store.client_id}
      highlight={!!highlightTitle}
      title={
        isNoSchoolDay ? (
          noClassTitle
        ) : (
          <>
            {extraTitle}
            {highlightTitle}
          </>
        )
      }
      to={buildAttendanceUrl(
        storeDate,
        params.schoolId,
        store?.client_id,
        thrStore?.client_id,
        studentStore?.client_id
      )}
      comment={comment}
    >
      {meal_provided && (
        <CommodityShowSmall
          commodities={store?.consumption?.commodities!}
          className="MealsStock"
        />
      )}
    </SidebarItem>
  );
};

export default MealAttendanceItem;
