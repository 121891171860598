import moment from "moment";

import {
  Attendance,
  getAllDeliveries,
  getAllPurchases,
  getAllStores,
  getOngoingSchoolYearByDate,
  getThrAttendanceStoresByDateRange,
  syncableSortFnDecDateTime,
} from "data-handler/ducks/stores";
import { getSchoolYearName } from "helpers/year";

import { getFilteredAttendanceTotal } from "helpers/attendance";
import {
  getEnrolmentAggregateOnDate,
  getFilteredEnrolmentTotalOnDate,
} from "helpers/enrolment";
import {
  studentKinds,
  prePrimaryLevels,
  staffLevels,
  allLevels,
  primaryLevels,
  studentLevels,
  purchaseDetailCategory,
  deliveryCategory,
  takeHomeRationCategory,
  StudentKind,
} from "SCConstants";
import {
  getAttendanceStores,
  getStudentAttendanceStores,
} from "data-handler/ducks/stores";
import { getWeekDay } from "helpers/dates";
import { getCurrentSchoolProfileWfpCommodities } from "data-handler/ducks/schools";
import {
  getStocksByDate,
  getDeliveriesWithinDates,
  getConsumptionWithinDates,
  getLossesWithinDates,
  getReturnsWithinDates,
  getBatchNumbersWithinDates,
  getPurchasedStocksByDate,
  getPurchasedCommoditiesWithinDates,
  getOtherCostWithinDates,
} from "helpers/report";
import { getCurrentSchool } from "data-handler/ducks/schools";
import { getReportByYearMonthDay } from "data-handler/ducks/reports";
import { RootState } from "data-handler/rootReducer";
import {
  getCommoditiesInStoreByCategory,
  getOtherCostsInStore,
  getPurchasedCommodities,
} from "helpers/stock";
import commoditiesDuck from "data-handler/ducks/commodities";
import { getPurchaseOtherCostsList } from "data-handler/ducks/purchaseOtherCosts";
import { displayStaffLevelsReport } from "components/SchoolReport/Absences";
import {
  getIdToNoMealReasonMapping,
  IdToNoMealReasonMapping,
} from "data-handler/ducks/noMealReasons";

// TODO: move many of these functions to more appropriate modules

/**
 * Returns the number of absentees on the given `date`, of the given kinds and levels.
 *
 * Does so by subtracting attendance from enrolment.
 */
const getFilteredAbsenceOnDate = ({
  date,
  kinds = studentKinds.map((kind) => kind.value) as StudentKind[],
  levels = allLevels.map((level) => level.value),
}: {
  date: string;
  kinds: StudentKind[];
  levels: string[];
}) => (state: RootState) => {
  const attendanceOnDate = getAttendanceStores(state).find((attendance) =>
    moment(date).isSame(attendance.occurred_on, "day")
  );
  if (!attendanceOnDate) {
    // According to business rules, missing attendance reports aren't absences.
    return 0;
  }
  const attendanceValue = getFilteredAttendanceTotal({
    attendance: attendanceOnDate,
    kinds,
    levels,
  });
  const enrolmentValue = getFilteredEnrolmentTotalOnDate({
    date,
    kinds,
    levels,
  })(state);

  const absenceValue =
    attendanceValue > enrolmentValue ? 0 : enrolmentValue - attendanceValue;
  return absenceValue;
};

export const getFilteredAbsenceInDateRange = ({
  date0,
  date1,
  kinds = studentKinds.map((kind) => kind.value),
  levels = allLevels.map((level) => level.value),
}: {
  date0: string;
  date1: string;
  kinds?: any[];
  levels?: any[];
}) => (state: RootState) => {
  let total = 0;
  const schoolDays = getSchoolDaysWithinDateRange(date0, date1)(state);
  schoolDays.forEach(({ date, is_schoolday }) => {
    if (is_schoolday) {
      total += getFilteredAbsenceOnDate({ date, kinds, levels })(state);
    }
  });
  return total;
};

/**
 * Returns the AttendanceReport for the given date, or undefined
 */
const getAttendanceReportOnDay = (date: string) => (state: RootState) =>
  getAttendanceStores(state).find((attendance) =>
    moment(date).isSame(attendance.occurred_on, "day")
  );

/**
 * Returns the AttendanceReport for the given date, or undefined
 */
const getStudentAttendanceReportOnDay = (date: string) => (state: RootState) =>
  getStudentAttendanceStores(state).find((attendance) =>
    moment(date).isSame(attendance.occurred_on, "day")
  );

/**
 * Returns true if there was a consumption with a WFP commodity in the Attendace
 */
export const getMealProvided = (
  attendance: Attendance,
  commoditiesList: any[]
) => {
  const commoditiesIdsList = commoditiesList.map((item) => item.id);

  return attendance?.consumption?.commodities?.find((item) =>
    commoditiesIdsList.includes(item.commodity)
  )
    ? true
    : false;
};

/**
 * Returns whether the attendance report records that it's a day off.
 *
 * TODO: don't hardcode day off checks
 */
const getAttendanceHasDayOffReasons = (
  attendance: Attendance,
  idToNoMealReasonRestriction: IdToNoMealReasonMapping
): boolean => {
  const noMealReasons = attendance.consumption?.no_meal_reasons;
  if (noMealReasons === undefined) {
    return false;
  } else {
    return noMealReasons?.some(
      (rId: number) => idToNoMealReasonRestriction[rId]?.is_no_school_day
    );
  }
};

const getConsumptionByCommodityId = (
  attendance: Attendance,
  commodityId: number
) => {
  const commodities =
    attendance && attendance.consumption && attendance.consumption.commodities;
  const commodity =
    commodities &&
    commodities.find((commodity) => commodity.commodity === commodityId);
  return (commodity && parseFloat(commodity.quantity)) || 0;
};

const getParticipantsPerLevels = (levels?: any[]) => {
  return (
    levels
      ?.map((level) => level.male + level.female)
      .reduce((a, b) => a + b, 0) || 0
  );
};

/**
 * Gets thr consumption by commodity id and level using the following logic:
 * (total THR consumption quantity in the attendance object / by total partipants) * participants per level
 */
const getThrConsumptionByCommodityIdAndLevel = (
  attendance: Attendance,
  commodityId: number,
  levels: any[]
) => {
  const commodities =
    attendance && attendance.consumption && attendance.consumption.commodities;
  const commodity =
    commodities &&
    commodities.find((commodity) => commodity.commodity === commodityId);

  const thrConsumptionQuantity =
    (commodity && parseFloat(commodity.quantity)) || 0;

  const allAttendanceLevels = attendance.levels;
  const thrTotalParticipants = getParticipantsPerLevels(allAttendanceLevels);

  const quantityPerParticipant =
    thrConsumptionQuantity !== 0 && thrTotalParticipants !== 0
      ? thrConsumptionQuantity / thrTotalParticipants
      : 0;

  const givenAttendanceLevels = allAttendanceLevels?.filter((item) =>
    levels.includes(item.level)
  );
  const participantsPerGivenLevels = getParticipantsPerLevels(
    givenAttendanceLevels
  );

  const quantityPerGivenLevels =
    quantityPerParticipant * participantsPerGivenLevels;

  return quantityPerGivenLevels;
};

/**
 * Returns a selector which returns whether `date` is a schoolday
 *
 * `date` is a schoolday when all of these are true:
 * - it occurs during a Schoolyear
 * - it's a schoolday according to that SchoolYear's weekdays
 * - the attendance report from that day has been provided
 * - the attendance report from that day doesn't report day-off reasons
 */
const getIsSchoolDay = (date: string) => (state: RootState): boolean => {
  const weekday = getWeekDay(date);
  const schoolYear = getOngoingSchoolYearByDate(date)(state);
  const idToNoMealReasonMapping = getIdToNoMealReasonMapping(state);

  // No ongoing SchoolYear -> definitely not a schoolday
  if (!schoolYear) {
    return false;
  }

  // Not a schoolday according to SchoolYear
  if (!schoolYear.weekdays[weekday]) {
    return false;
  }

  // If no information has yet been provided for that day, consider it a schoolday
  const attendanceOnDay = getAttendanceReportOnDay(date)(state);
  if (attendanceOnDay === undefined) {
    return true;
  }

  // Every other check passed, but it may still be a non-schoolday
  return !getAttendanceHasDayOffReasons(
    attendanceOnDay,
    idToNoMealReasonMapping
  );
};

const getSchoolDaysWithinDateRange = (date0: string, date1: string) => (
  state: RootState
) => {
  let date = moment(date0);
  const schoolDays = [];
  while (date.isSameOrBefore(date1)) {
    schoolDays.push({
      date: date.format("YYYY-MM-DD"),
      is_schoolday: getIsSchoolDay(date.format("YYYY-MM-DD"))(state),
    });
    date.add(1, "day"); // in place
  }
  return schoolDays;
};

export const getStockMovementsWithinDateRangeByCategory = (
  date0: string,
  date1: string,
  previousReportDates?: {
    year: string;
    month: string;
    startDay: number;
    endDay: number;
  },
  category: string = deliveryCategory
) => (state: RootState) => {
  const allStores = getAllStores(state);
  const openingStock = getStocksByDate(state, date0, category, true) as any;
  const endingStock = getStocksByDate(state, date1, category) as any;

  const deliveries = getDeliveriesWithinDates(
    state,
    date0,
    date1,
    false,
    category
  ) as any;

  const commoditiesIndex = commoditiesDuck.getIndex(state);
  const commodities = getCommoditiesInStoreByCategory(
    allStores,
    commoditiesIndex,
    true,
    category,
    date1
  );
  const consumption = getConsumptionWithinDates(
    getAllStores,
    state,
    date0,
    date1,
    category
  );
  const losses = getLossesWithinDates(state, date0, date1, category) as any;
  const returns = getReturnsWithinDates(state, date0, date1, category) as any;
  const batch_nos = getBatchNumbersWithinDates(
    state,
    date0,
    date1,
    previousReportDates!,
    commodities,
    category
  ) as any;

  return commodities.map((commodity: { id: number }) => ({
    commodity: commodity.id,
    batch_nos: batch_nos[commodity.id] || "",
    qty_initial: openingStock[commodity.id] || 0,
    qty_received: deliveries[commodity.id] || 0,
    qty_distributed: consumption[commodity.id] || 0,
    qty_lost: losses[commodity.id] || 0,
    qty_returned: returns[commodity.id] || 0,
    qty_final: endingStock[commodity.id] || 0,
  }));
};

export const getPurchasedStockMovementsWithinDateRange = ({
  date0,
  date1,
}: {
  date0: string;
  date1: string;
}) => (state: RootState) => {
  const allStores = getAllStores(state);
  const endingStock = getPurchasedStocksByDate(
    state,
    date1,
    purchaseDetailCategory
  ) as any;
  const purchases = getPurchasedCommoditiesWithinDates(
    state,
    date0,
    date1,
    "quantity"
  ) as any;
  const openingStock = getPurchasedStocksByDate(
    state,
    date0,
    purchaseDetailCategory,
    true
  ) as any;
  const commoditiesIndex = commoditiesDuck.getIndex(state);
  const commodities = getCommoditiesInStoreByCategory(
    allStores,
    commoditiesIndex,
    false,
    purchaseDetailCategory,
    date1
  );
  const consumption = getConsumptionWithinDates(
    getAllStores,
    state,
    date0,
    date1,
    purchaseDetailCategory
  ) as any;
  const losses = getLossesWithinDates(
    state,
    date0,
    date1,
    purchaseDetailCategory
  ) as any;

  return commodities.map((commodity: { id: number }) => ({
    commodity: commodity.id,
    qty_initial: openingStock[commodity.id] || 0,
    qty_received: purchases[commodity.id] || 0,
    qty_distributed: consumption[commodity.id] || 0,
    qty_lost: losses[commodity.id] || 0,
    qty_final: endingStock[commodity.id] || 0,
  }));
};

export const getPurchasedStockMovementsExpendituresWithinDateRange = ({
  date0,
  date1,
}: {
  date0: string;
  date1: string;
}) => (state: RootState) => {
  const purchaseStores = getAllPurchases(state);
  const quantity = getPurchasedCommoditiesWithinDates(
    state,
    date0,
    date1,
    "quantity"
  ) as any;
  const totalPaid = getPurchasedCommoditiesWithinDates(
    state,
    date0,
    date1,
    "total_paid"
  ) as any;
  const comments = getPurchasedCommoditiesWithinDates(
    state,
    date0,
    date1,
    "comments"
  ) as any;
  const otherCostTotalPaid = getOtherCostWithinDates(
    state,
    date0,
    date1,
    "total_paid"
  ) as any;
  // TODO: comments currently not working for offline reports, if theres more than one other cost it will display invalid date
  const otherCostComments = getOtherCostWithinDates(
    state,
    date0,
    date1,
    "comments"
  ) as any;
  const date = getPurchasedCommoditiesWithinDates(
    state,
    date0,
    date1,
    "date"
  ) as any;
  const otherCostDate = getOtherCostWithinDates(
    state,
    date0,
    date1,
    "date"
  ) as any;

  const purchaseOtherCosts = getPurchaseOtherCostsList(state);
  const commoditiesIndex = commoditiesDuck.getIndex(state);
  const commodities = getPurchasedCommodities(
    purchaseStores,
    commoditiesIndex,
    date0,
    date1
  );
  const otherCosts = getOtherCostsInStore(
    purchaseStores,
    purchaseOtherCosts,
    date0,
    date1
  );

  const otherCostsList = otherCosts?.map(
    (otherCost: { id: number; name: string }) => ({
      item: `Other Costs (${otherCost?.name})`,
      id: otherCost?.id,
      quantity: "N/A",
      unit: "N/A",
      total_paid: otherCostTotalPaid[otherCost?.id],
      comments: otherCostComments[otherCost?.id] || "",
      date: otherCostDate[otherCost?.id]?.slice(0, -2),
    })
  );
  const commoditiesList = commodities.map((commodity) => ({
    item: commodity.id,
    id: commodity.id,
    quantity: quantity[commodity.id],
    unit: "2",
    total_paid: totalPaid[commodity.id],
    comments: comments[commodity.id] || "",
    date: date[commodity.id]?.slice(0, -2),
  }));

  return otherCostsList
    ? [...commoditiesList, ...otherCostsList]
    : commoditiesList;
};
/**
 * Returns a brief summary of attendance and consumption on the given `date`
 */
const getSchoolDaySummary = (date: string) => (state: RootState) => {
  const wfpCommodities = getCurrentSchoolProfileWfpCommodities(state);

  const summary = {
    date,
    is_school_day: getIsSchoolDay(date)(state),
    is_consumption_day: false,
    attendance_per_kind: {} as any,
    student_attendance_per_kind: {} as any,
    consumption_per_commodity: {} as any,
  };

  const attendance = getAttendanceReportOnDay(date)(state);
  const studentAttendance = getStudentAttendanceReportOnDay(date)(state);

  if (!attendance) {
    // Early return when no AttendanceReport on that day
    studentKinds.forEach((kind) => {
      summary.attendance_per_kind[kind.key] = 0;
    });

    wfpCommodities.forEach((commodity: { id: number }) => {
      summary.consumption_per_commodity[commodity.id] = 0;
    });
  }

  if (!studentAttendance) {
    studentKinds.forEach((kind) => {
      summary.student_attendance_per_kind[kind.key] = 0;
    });
  }

  if (!attendance && !studentAttendance) {
    return summary;
  }

  if (attendance) {
    summary.is_consumption_day = getMealProvided(attendance, wfpCommodities);
  }

  studentKinds.forEach((kind) => {
    summary.attendance_per_kind[kind.value] = getFilteredAttendanceTotal({
      attendance,
      kinds: [kind.value],
    } as { attendance: Attendance; kinds: StudentKind[] });
  });

  studentKinds.forEach((kind) => {
    summary.student_attendance_per_kind[
      kind.value
    ] = getFilteredAttendanceTotal({
      attendance: studentAttendance,
      kinds: [kind.value],
    } as { attendance: Attendance; kinds: StudentKind[] });
  });

  if (attendance) {
    wfpCommodities.forEach((commodity: { id: number }) => {
      summary.consumption_per_commodity[
        commodity.id
      ] = getConsumptionByCommodityId(attendance, commodity.id);
    });
  }

  return summary;
};

/**
 * Returns a brief summary of thr participants and consumption per level
 */
const getThrConsumptionLevelSummary = (
  attendances: Attendance[],
  levelObject: any
) => (state: RootState) => {
  const wfpCommodities = getCurrentSchoolProfileWfpCommodities(state);

  const { level, levels } = levelObject;

  const summary = {
    level: level,
    attendance_per_kind: {} as any,
    student_attendance_per_kind: {} as any,
    consumption_per_commodity: {} as any,
  };

  attendances.forEach((attendance) => {
    studentKinds.forEach((kind) => {
      summary.attendance_per_kind[kind.value] =
        (summary.attendance_per_kind[kind.value] || 0) +
        getFilteredAttendanceTotal({
          attendance,
          kinds: [kind.value],
          levels: levels,
        } as { attendance: Attendance; kinds: StudentKind[] });
    });
    wfpCommodities.forEach((commodity: { id: number }) => {
      summary.consumption_per_commodity[commodity.id] =
        (summary.consumption_per_commodity[commodity.id] || 0) +
        getThrConsumptionByCommodityIdAndLevel(
          attendance,
          commodity.id,
          levels
        );
    });
  });

  return summary;
};

/**
 * Returns a selector of aggregated thr data per levels`.
 * Useful for reports
 */
export const getThrDataPerLevelAggregateInDateRange = (
  date0: string,
  date1: string
) => (state: RootState) => {
  const ongoingSchoolYear = getOngoingSchoolYearByDate(date0)(state);
  const displayStaffLevelEnrollments = ongoingSchoolYear?.levels.find(
    (level) => level?.level === "staff"
  )
    ? true
    : false;
  const level_total_rows = [
    { name: "pre", levels: prePrimaryLevels },
    { name: "primary", levels: primaryLevels },
    { name: "total", levels: studentLevels },
  ];
  if (displayStaffLevelEnrollments) {
    level_total_rows.push({ name: "staff", levels: staffLevels });
  }

  if (!ongoingSchoolYear) {
    return {
      rows: [],
      totals_rows: [],
    };
  }

  const attendances = getThrAttendanceStoresByDateRange(date0, date1)(state);

  const rows = ongoingSchoolYear.levels.map((level) => {
    return getThrConsumptionLevelSummary(attendances, {
      level: level.level,
      levels: [level.level],
    })(state);
  });

  const totals_rows = level_total_rows.map((level) => {
    return getThrConsumptionLevelSummary(attendances, {
      level: level.name,
      levels: level.levels.map((level) => level.value),
    })(state);
  });

  return { rows, totals_rows };
};

/**
 * Returns (string): formatted date of the latest delivery before the given `date`
 */
export const getLatestDeliveryDateOnDate = ({
  date,
  state,
}: {
  date: string;
  state: RootState;
}) => {
  const latest_delivery = getAllDeliveries(state)
    .filter((delivery) =>
      moment(delivery.delivered_at).isSameOrBefore(date, "day")
    )
    .sort(syncableSortFnDecDateTime)[0];

  return (
    latest_delivery && moment(latest_delivery.delivered_at).format("YYYY-MM-DD")
  );
};

/**
 * Calculates all MonthlyReport aggregates for the given date range
 */
export default ({
  state,
  year,
  month,
  startDay,
  endDay,
  previousReportDates,
}: {
  state: RootState;
  year: string;
  month: string;
  startDay: number;
  endDay: number;
  previousReportDates?: {
    year: string;
    month: string;
    startDay: number;
    endDay: number;
  };
}) => {
  const date0 = moment(`${year}/${month}/${startDay}`);
  let date1 = moment(`${year}/${month}/${endDay}`);

  const schoolYear =
    getOngoingSchoolYearByDate(date0.format("YYYY-MM-DD"))(state) ||
    getOngoingSchoolYearByDate(date1.format("YYYY-MM-DD"))(state);

  // Checks if end of the school year is before the end of the month.
  // if so changes date1 to be the end of the school year not end of the month.
  if (schoolYear) {
    const school_year_ends_on = schoolYear.ends_on ? schoolYear.ends_on : "";
    if (date1.format("YYYY-MM-DD") > school_year_ends_on) {
      date1 = moment(schoolYear.ends_on);
    }
  }
  // General data section

  const school_year_name =
    schoolYear === null ? "" : getSchoolYearName(schoolYear);

  const {
    name: school_name,
    programme_manager,
    closest_wfp_office,
    local_education_authority,
    regional_education_authority,
  } = getCurrentSchool(state);

  const latest_delivery_date = getLatestDeliveryDateOnDate({
    state,
    date: date1.format("YYYY-MM-DD"),
  });

  // Enrolment section

  const {
    rows: enrolment_rows,
    totals_rows: enrolment_totals_rows,
  } = getEnrolmentAggregateOnDate(date1)(state);

  // Absences section

  const absence_rows =
    schoolYear === null
      ? []
      : schoolYear.levels.map((level) => {
          const row = {
            level: level.level,
            total: getFilteredAbsenceInDateRange({
              date0: date0.format("YYYY-MM-DD"),
              date1: date1.format("YYYY-MM-DD"),
              levels: [level.level],
            })(state),
            custom_name: level.hasOwnProperty(`custom_name`)
              ? level[`custom_name` as "custom_name"]
              : undefined,
          } as any;
          studentKinds.forEach((kind) => {
            row[kind.value] = getFilteredAbsenceInDateRange({
              date0: date0.format("YYYY-MM-DD"),
              date1: date1.format("YYYY-MM-DD"),
              kinds: [kind.value],
              levels: [level.level],
            })(state);
          });
          return row;
        });

  const level_total_rows = [
    { name: "pre", levels: prePrimaryLevels },
    { name: "primary", levels: primaryLevels },
    { name: "total", levels: studentLevels },
  ];
  if (displayStaffLevelsReport(absence_rows)) {
    level_total_rows.push({ name: "staff", levels: staffLevels });
  }
  const absence_totals_rows = level_total_rows.map((totalType) => {
    const row = {
      name: totalType.name,
      total: getFilteredAbsenceInDateRange({
        date0: date0.format("YYYY-MM-DD"),
        date1: date1.format("YYYY-MM-DD"),
        levels: totalType.levels.map((level) => level.value),
      })(state),
    } as any;
    studentKinds.forEach((kind) => {
      row[kind.value] = getFilteredAbsenceInDateRange({
        date0: date0.format("YYYY-MM-DD"),
        date1: date1.format("YYYY-MM-DD"),
        kinds: [kind.value],
        levels: totalType.levels.map((level) => level.value),
      })(state);
    });

    return row;
  });

  // Stock movements section

  // On-site
  const stock_movement_rows_delivery = getStockMovementsWithinDateRangeByCategory(
    date0.format("YYYY-MM-DD"),
    date1.format("YYYY-MM-DD"),
    previousReportDates
  )(state);

  const stock_movement_totals_row_delivery = {
    name: "total",
    qty_initial: 0,
    qty_received: 0,
    qty_distributed: 0,
    qty_lost: 0,
    qty_returned: 0,
    qty_final: 0,
  };

  stock_movement_rows_delivery.forEach(
    (row: {
      qty_initial: number;
      qty_received: number;
      qty_distributed: number;
      qty_lost: number;
      qty_returned: number;
      qty_final: number;
    }) => {
      stock_movement_totals_row_delivery.qty_initial += row.qty_initial;
      stock_movement_totals_row_delivery.qty_received += row.qty_received;
      stock_movement_totals_row_delivery.qty_distributed += row.qty_distributed;
      stock_movement_totals_row_delivery.qty_lost += row.qty_lost;
      stock_movement_totals_row_delivery.qty_returned += row.qty_returned;
      stock_movement_totals_row_delivery.qty_final += row.qty_final;
    }
  );

  // THR
  const stock_movement_rows_takehomeration = getStockMovementsWithinDateRangeByCategory(
    date0.format("YYYY-MM-DD"),
    date1.format("YYYY-MM-DD"),
    previousReportDates,
    takeHomeRationCategory
  )(state);
  const stock_movement_totals_row_takehomeration = {
    name: "total",
    qty_initial: 0,
    qty_received: 0,
    qty_distributed: 0,
    qty_lost: 0,
    qty_returned: 0,
    qty_final: 0,
  };

  stock_movement_rows_takehomeration.forEach(
    (row: {
      qty_initial: number;
      qty_received: number;
      qty_distributed: number;
      qty_lost: number;
      qty_returned: number;
      qty_final: number;
    }) => {
      stock_movement_totals_row_takehomeration.qty_initial += row.qty_initial;
      stock_movement_totals_row_takehomeration.qty_received += row.qty_received;
      stock_movement_totals_row_takehomeration.qty_distributed +=
        row.qty_distributed;
      stock_movement_totals_row_takehomeration.qty_lost += row.qty_lost;
      stock_movement_totals_row_takehomeration.qty_returned += row.qty_returned;
      stock_movement_totals_row_takehomeration.qty_final += row.qty_final;
    }
  );

  // Purchases
  const stock_movement_rows_purchasedetail = getPurchasedStockMovementsWithinDateRange(
    {
      date0: date0.format("YYYY-MM-DD"),
      date1: date1.format("YYYY-MM-DD"),
    }
  )(state);
  const stock_movement_totals_row_purchasedetail = {
    name: "total",
    qty_initial: 0,
    qty_received: 0,
    qty_distributed: 0,
    qty_lost: 0,
    qty_final: 0,
  };

  stock_movement_rows_purchasedetail.forEach(
    (row: {
      qty_initial: number;
      qty_received: number;
      qty_distributed: number;
      qty_lost: number;
      qty_final: number;
    }) => {
      stock_movement_totals_row_purchasedetail.qty_initial += row.qty_initial;
      stock_movement_totals_row_purchasedetail.qty_received += row.qty_received;
      stock_movement_totals_row_purchasedetail.qty_distributed +=
        row.qty_distributed;
      stock_movement_totals_row_purchasedetail.qty_lost += row.qty_lost;
      stock_movement_totals_row_purchasedetail.qty_final += row.qty_lost;
    }
  );

  // Purchased stock movements expenditures section

  const food_expenditure_rows = getPurchasedStockMovementsExpendituresWithinDateRange(
    {
      date0: date0.format("YYYY-MM-DD"),
      date1: date1.format("YYYY-MM-DD"),
    }
  )(state);
  const food_expenditure_totals_row = {
    name: "total",
    quantity: 0,
    unit: "2",
    date: "",
    total_paid: 0,
    comments: "",
  };

  food_expenditure_rows.forEach((row) => {
    if (row.quantity === "N/A") {
      return;
    } else {
      food_expenditure_totals_row.quantity += row.quantity;
    }
    food_expenditure_totals_row.unit += "";
    food_expenditure_totals_row.date += "";
    food_expenditure_totals_row.total_paid += row.total_paid;
    food_expenditure_totals_row.comments += "";
  });
  // School day summaries section

  const month_days = [];
  for (
    let date = moment(date0);
    date1.isSameOrAfter(date, "day");
    date.add(1, "day")
  ) {
    month_days.push(moment(date).format("YYYY-MM-DD"));
  }

  const school_day_summary_rows = month_days.map((date) =>
    getSchoolDaySummary(date)(state)
  );

  const school_day_summary_totals_row = {
    school_days: 0,
    consumption_days: 0,
    attendance_per_kind: {} as any,
    student_attendance_per_kind: {} as any,
    consumption_per_commodity: {} as any,
  };

  school_day_summary_rows.forEach((row) => {
    if (row.is_school_day) {
      school_day_summary_totals_row.school_days++;
    }
    if (row.is_consumption_day) {
      school_day_summary_totals_row.consumption_days++;
    }
    Object.entries(row.attendance_per_kind).forEach(([kind, value]) => {
      school_day_summary_totals_row.attendance_per_kind[kind] =
        (school_day_summary_totals_row.attendance_per_kind[kind] || 0) + value;
    });
    Object.entries(row.student_attendance_per_kind).forEach(([kind, value]) => {
      school_day_summary_totals_row.student_attendance_per_kind[kind] =
        (school_day_summary_totals_row.student_attendance_per_kind[kind] || 0) +
        value;
    });
    Object.entries(row.consumption_per_commodity).forEach(
      ([commodity, value]) => {
        school_day_summary_totals_row.consumption_per_commodity[commodity] =
          (school_day_summary_totals_row.consumption_per_commodity[commodity] ||
            0) + value;
      }
    );
  });

  // THR participants and consumption section

  const {
    rows: thr_data_per_level_rows,
    totals_rows: thr_data_per_level_totals_rows,
  } = getThrDataPerLevelAggregateInDateRange(
    date0.format("YYYY-MM-DD"),
    date1.format("YYYY-MM-DD")
  )(state);

  const reportFromBackend = getReportByYearMonthDay(
    state,
    Number(year),
    Number(month),
    startDay
  );
  const log_entries = reportFromBackend ? reportFromBackend.log_entries : [];

  const negative_stock = () => {
    return (
      (stock_movement_rows_delivery.filter(
        (row: any) => parseFloat(row.qty_final) < 0
      ).length > 0
        ? true
        : false) ||
      (stock_movement_rows_takehomeration.filter(
        (row: any) => parseFloat(row.qty_final) < 0
      ).length > 0
        ? true
        : false) ||
      (stock_movement_rows_purchasedetail.filter(
        (row: any) => parseFloat(row.qty_final) < 0
      ).length > 0
        ? true
        : false)
    );
  };

  return {
    aggregates: {
      absence_rows,
      absence_totals_rows,
      closest_wfp_office,
      enrolment_rows,
      enrolment_totals_rows,
      latest_delivery_date,
      local_education_authority,
      programme_manager,
      regional_education_authority,
      school_day_summary_rows,
      school_day_summary_totals_row,
      stock_movement_rows_delivery,
      stock_movement_totals_row_delivery,
      stock_movement_rows_takehomeration,
      stock_movement_totals_row_takehomeration,
      stock_movement_rows_purchasedetail,
      stock_movement_totals_row_purchasedetail,
      food_expenditure_rows,
      food_expenditure_totals_row,
      thr_data_per_level_rows,
      thr_data_per_level_totals_rows,
    },
    isPreview: true, // Add a flag to let the FE know that this isn't from the BE
    negative_stock: negative_stock(),
    log_entries,
    month,
    school_name,
    school_year_name,
    state: "open",
    year,
    start_day: startDay,
    end_day: endDay,
  };
};
