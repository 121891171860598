import {
  getCurrentSchoolYear,
  getSchoolYearByDate,
} from "data-handler/ducks/stores";
import React from "react";
import { useSelector } from "react-redux";
import { Blockquote } from "@wfp/ui";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { getModelDateFormat } from "components/UneditableMessage";

const UneditableYear = ({ currentStoreData }) => {
  const currentYearStore = useSelector((state) => getCurrentSchoolYear(state));
  const selectedDeliverySchoolYear = useSelector(
    getSchoolYearByDate(
      getModelDateFormat(currentStoreData) || moment().format("YYYY-MM-DD")
    )
  );

  if (
    currentYearStore === null ||
    currentYearStore === selectedDeliverySchoolYear
  ) {
    return "";
  }

  return (
    <Blockquote kind="warning">
      <p className="uneditable-year-warning">
        <FormattedMessage
          id="uneditable.yearShowError"
          defaultMessage="You have created a new school year, therefore you cannot edit the previous school year data anymore."
        />
      </p>
    </Blockquote>
  );
};

export default UneditableYear;
