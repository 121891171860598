import {
  getCurrentSchoolYear,
  getSchoolYearByDate,
} from "data-handler/ducks/stores";
import React from "react";
import { useSelector } from "react-redux";
import { Blockquote } from "@wfp/ui";
import { FormattedMessage } from "react-intl";
import moment from "moment";

import styles from "./styles.module.scss";

export const getModelDateFormat = (currentStoreData) => {
  switch (currentStoreData?.model) {
    case "attendance":
      return currentStoreData?.occurred_on;
    case "delivery":
      return currentStoreData?.delivered_at;
    case "purchasedetail":
      return currentStoreData?.purchased_at;
    case "incident":
      return currentStoreData?.occurred_at;
    case "year":
      return currentStoreData?.starts_on;
    default:
      return undefined;
  }
};

const UneditableMessage = ({ currentStoreData }) => {
  const currentYearStore = useSelector((state) => getCurrentSchoolYear(state));
  const selectedDeliverySchoolYear = useSelector(
    getSchoolYearByDate(
      getModelDateFormat(currentStoreData) || moment().format("YYYY-MM-DD")
    )
  );

  if (
    currentYearStore === null ||
    currentYearStore === selectedDeliverySchoolYear
  ) {
    return null;
  }
  return (
    <Blockquote kind="warning">
      <p className={styles.warningMessage}>
        <FormattedMessage
          id="uneditable.yearError"
          defaultMessage="You have created a new school year, therefore you can't edit previous year data."
        />
      </p>
    </Blockquote>
  );
};

export default UneditableMessage;
