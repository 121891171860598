import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { NavLink, useParams } from "react-router-dom";
import moment from "moment";

import {
  Button,
  Module,
  ModuleBody,
  List,
  ListItem,
  Blockquote,
} from "@wfp/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import DeleteModalButton from "components/DeleteModalButton";
import {
  getCurrentSchoolYear,
  getPreviousSchoolYears,
  getSchoolYearByDate,
} from "data-handler/ducks/stores";
import Section from "components/Section";
import SubTitle from "components/SubTitle";
import CommodityShow from "components/CommodityShow";
import MainContent from "components/MainContent";

import { getReportedStores } from "helpers/report";

import Gate from "../Gate";
import { UserPermissionRoles } from "../../SCConstants";
import { getIsSyncableLocked } from "../../data-handler/ducks/stores";
import UneditableMessage, {
  getModelDateFormat,
} from "components/UneditableMessage";

import "./cash-to-school-show.scss";
import OtherCostShow from "components/OtherCostShow";
import { getVendorCategories } from "data-handler/ducks/vendors";

export const getSelectedVendorName = (
  selectedVendorCategory,
  vendorCategories
) => {
  const allVendorCategories = Object.values(vendorCategories?.results || {});

  return allVendorCategories.find(
    (vendor) => vendor.id === selectedVendorCategory
  )?.name;
};
const PurchasesShow = ({ purchaseCurrentStoreData }) => {
  const params = useParams();
  const reportedStores = useSelector((state) => getReportedStores(state));
  const isReported = reportedStores[params.item];
  const isStockRebalance =
    purchaseCurrentStoreData?.is_negative_stock_rebalance === true;
  const formattedPurchaseDate = moment(
    purchaseCurrentStoreData.purchased_at
  ).format("dddd, DD.MM.YYYY");
  const grandTotal = Number(purchaseCurrentStoreData.total_paid);
  const isLocked = useSelector((state) =>
    getIsSyncableLocked(state, purchaseCurrentStoreData)
  );
  const selectedPurchaseSchoolYear = useSelector(
    getSchoolYearByDate(
      getModelDateFormat(purchaseCurrentStoreData) ||
        moment().format("YYYY-MM-DD")
    )
  );
  const vendorCategories = useSelector(getVendorCategories);
  const selectedVendorCategory = purchaseCurrentStoreData.vendor_category;
  const previousSchoolYears = useSelector(getPreviousSchoolYears)[0];
  const currentYear = useSelector((state) => getCurrentSchoolYear(state)); //users current year

  return (
    <MainContent title={formattedPurchaseDate} className="Purchases-content">
      <Section>
        <Gate
          forbidRoles={[UserPermissionRoles.is_viewer]}
          isForbidden={!isLocked}
        >
          <Blockquote kind="warning">
            <p className="font-weight-bold">
              <FormattedMessage
                id="app.error-message"
                defaultMessage="You have closed this months report. In order to edit, please reopen the report."
              />
            </p>
          </Blockquote>
        </Gate>
        <UneditableMessage
          purchaseCurrentStoreData={purchaseCurrentStoreData}
        />
      </Section>
      <SubTitle
        additional={
          <Gate
            forbidRoles={[UserPermissionRoles.is_viewer]}
            isForbidden={
              isLocked ||
              (currentYear !== null &&
                currentYear !== selectedPurchaseSchoolYear) ||
              (currentYear === null &&
                previousSchoolYears !== selectedPurchaseSchoolYear)
            }
          >
            {purchaseCurrentStoreData.type !== "delete" &&
              !isStockRebalance && (
                <DeleteModalButton
                  currentStoreData={purchaseCurrentStoreData}
                />
              )}
            <NavLink
              to={`/school/${params.schoolId}/deliveries/${params.item}/purchase-detail`}
              className="edit-nav-link"
            >
              <Button
                kind="secondary"
                icon={<FontAwesomeIcon icon={faChevronRight} />}
                disabled={isReported || isStockRebalance}
              >
                <FormattedMessage
                  id="PurchasesShow.editPurchase"
                  defaultMessage="Edit Purchase"
                />
              </Button>
            </NavLink>
          </Gate>
        }
      >
        <FormattedMessage
          id="PurchasesShow.PurchaseFrom"
          defaultMessage="Purchase from {date}"
          values={{
            date: formattedPurchaseDate,
          }}
        />
      </SubTitle>
      <Module noMargin>
        <ModuleBody>
          <List kind="details" colon>
            <ListItem
              title={
                <FormattedMessage
                  id="PurchasesShow.Vendor"
                  defaultMessage="Vendor"
                />
              }
            >
              {getSelectedVendorName(selectedVendorCategory, vendorCategories)
                ? getSelectedVendorName(
                    selectedVendorCategory,
                    vendorCategories
                  )
                : purchaseCurrentStoreData.vendor_name}
            </ListItem>
            <ListItem
              title={
                <FormattedMessage
                  id="PurchasesShow.formattedPurchaseDate"
                  defaultMessage="Purchase date"
                />
              }
            >
              {formattedPurchaseDate}
            </ListItem>
            <ListItem
              title={
                <FormattedMessage
                  id="PurchasesShow.totalPaid"
                  defaultMessage="Total amount paid"
                />
              }
            >
              {grandTotal}
            </ListItem>
          </List>
        </ModuleBody>
      </Module>
      <CommodityShow
        emptyTitle={
          <FormattedMessage
            id="PurchasesShow.noCommoditiesPurchased"
            defaultMessage="No commodities purchased"
          />
        }
        title={
          <FormattedMessage
            id="PurchasesShow.purchasedCommodities"
            defaultMessage="Purchased commodities"
          />
        }
        model={purchaseCurrentStoreData.model}
        commodities={purchaseCurrentStoreData.commodities}
      />
      <OtherCostShow
        emptyTitle={
          <FormattedMessage
            id="PurchasesShow.noOtherCosts"
            defaultMessage="No other costs"
          />
        }
        title={
          <FormattedMessage
            id="PurchasesShow.otherCosts"
            defaultMessage="Other costs"
          />
        }
        model={purchaseCurrentStoreData.model}
        other_costs={purchaseCurrentStoreData?.other_costs}
      />
      <Gate
        forbidRoles={[UserPermissionRoles.is_viewer]}
        isForbidden={
          isLocked ||
          (currentYear !== null &&
            currentYear !== selectedPurchaseSchoolYear) ||
          (currentYear === null &&
            previousSchoolYears !== selectedPurchaseSchoolYear)
        }
      >
        {" "}
        <NavLink
          to={`/school/${params.schoolId}/deliveries/${params.item}/repeat-purchase`}
          className="repeat-purchase"
        >
          <Button
            kind="secondary"
            icon={<FontAwesomeIcon icon={faChevronRight} />}
            disabled={isReported || isStockRebalance}
          >
            <FormattedMessage
              id="PurchasesShow.RepeatPurchase"
              defaultMessage="Repeat purchase"
            />
          </Button>
        </NavLink>{" "}
      </Gate>
    </MainContent>
  );
};

export default PurchasesShow;
