import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { InlineLoading } from "@wfp/ui";
import moment, { MomentInput } from "moment";

import {
  getAllStores,
  getSchoolYearsByYearAndMonth,
  getUnsyncedStores,
} from "data-handler/ducks/stores";
import { getCurrentSchool } from "data-handler/ducks/schools";
import {
  getCurrentSchoolYear,
  getPreviousSchoolYears,
} from "data-handler/ducks/stores";
import reportsDuck from "data-handler/ducks/reports";

// "Report" interface renamed "IReport" to avoid clash with default export
import { Report as IReport, ReportAction } from "data-handler/ducks/stores";
import { getLoggedInOffline } from "data-handler/ducks/auth";

import SidebarWrapper from "components/SidebarWrapper";
import StartASchoolYearFirst from "components/StartASchoolYearFirst";
import generateReportPreview from "components/Report/generateReportPreview";
import ReportItem from "components/ItemSwitcher/ReportItem";
import SchoolYearGroupDropdown from "components/SchoolYearGroupDropdown";

import ReportContent from "./ReportContent";
import ReportModalAmend from "./ReportModalAmend";
import ReportModalApprove from "./ReportModalApprove";
import ReportModalClose from "./ReportModalClose";
import ReportModalReject from "./ReportModalReject";
import ReportModalReopen from "./ReportModalReopen";
import ReportModalSign from "./ReportModalSign";
import ReportModalSubmit from "./ReportModalSubmit";
import {
  getFilteredReportSignatures,
  removeReportSignatures,
} from "data-handler/ducks/reportSignatures";

import { Store, getSyncableMoment } from "data-handler/ducks/stores";
import { RootState } from "data-handler/rootReducer";

export const REPORT_FETCHER = "Report";

export type YearMonth = {
  schoolYearId: number;
  year: number;
  month: number;
  startDay: number;
  endDay: number;
};

/**
 * Generates {year: <int>, month: <int>} for all months in which syncables occur
 */
export const getReportableMonthsBySyncables = (
  syncables: Store[],
  state: RootState
): YearMonth[] => {
  const yearsMonths = new Set<String>();
  syncables.forEach((syncable) => {
    const date = getSyncableMoment(syncable);
    yearsMonths.add(date?.format("YYYY-MM"));
  });
  let results: any[] = [];
  [...yearsMonths].forEach((item) => {
    const year = parseInt(item?.slice(0, 4));
    const month = parseInt(item?.slice(5, 7));
    const filteredSchoolYears = getSchoolYearsByYearAndMonth(
      year,
      month
    )(state);
    results.push(
      ...filteredSchoolYears.map((schoolYear) => {
        // Get the first day of the report's month
        const startOfMonth = moment()
          .year(year)
          .month(month - 1)
          .date(1);

        // Get the last day of the report's month
        const endOfMonth = moment()
          .year(year)
          .month(month)
          .date(1)
          .subtract(1, "day");

        // Calculate the start day and end day of the report to be used when
        // using a generated report
        const reportStartDay = startOfMonth.isBefore(schoolYear.starts_on)
          ? moment(schoolYear.starts_on).date()
          : startOfMonth.date();
        const reportEndDay = endOfMonth.isAfter(schoolYear.ends_on)
          ? moment(schoolYear.ends_on).date()
          : endOfMonth.date();
        return {
          year,
          month,
          schoolYearId: schoolYear.object_id
            ? schoolYear.object_id
            : schoolYear.client_id,
          startDay: reportStartDay,
          endDay: reportEndDay,
        };
      })
    );
  });
  return results;
};

const getReportAndPrevious = (
  yearsMonths: any[],
  reports: any[],
  oldestSchoolYearStartDay: MomentInput | undefined,
  mostRecentSchoolYearEndDay: MomentInput | undefined
) => {
  if (
    (yearsMonths.length === 0 && reports.length === 0) ||
    !oldestSchoolYearStartDay ||
    !mostRecentSchoolYearEndDay
  ) {
    return [];
  }

  let lastReportDateUsed = oldestSchoolYearStartDay;

  const mappedYearsMonths = yearsMonths.map(
    ({ year, month, schoolYearId, startDay, endDay }) => {
      // Update the lastReportDateUsed if necessary
      if (moment(`${year}/${month}/${endDay}`).isAfter(lastReportDateUsed)) {
        lastReportDateUsed = `${year}/${month}/${endDay}`;
      }

      const report = reports.find(
        (report) =>
          report.year === year &&
          report.month === month &&
          report.start_day === startDay &&
          report.end_day === endDay
      );
      return report
        ? {
            ...report,
            schoolYearId,
          }
        : {
            year,
            month,
            isStub: true,
            schoolYearId,
            start_day: startDay,
            end_day: endDay,
          };
    }
  );

  const fullReports = mappedYearsMonths
    // Concat missing reports from the backend (if any). This can happen if some reports had
    // been created by synced data, but then that data has been deleted from the system.
    // Filter the reports from the backend based on the lastReportDateUsed and mostRecentSchoolYearEndDay
    .concat(
      reports.filter(
        (report) =>
          moment(
            `${report.year}/${report.month}/${report.start_day}`
          ).isSameOrAfter(lastReportDateUsed) &&
          (mostRecentSchoolYearEndDay
            ? moment(
                `${report.year}/${report.month}/${report.start_day}`
              ).isSameOrBefore(mostRecentSchoolYearEndDay)
            : true)
      )
    )
    .map((report) => {
      return {
        ...report,
        // Horrible magic to make reports play nice with ItemSwitcher (TODO: clean)
        model: "report",
        client_id: `${report.schoolYearId}-${report.year}-${report.month}`,
        // More magic: insert missing BE aggregates (TODO: remove once BE has them)
        aggregates: {
          closest_wfp_office: "closest_wfp_office",
          latest_delivery_date: "latest_delivery_date",
          local_education_authority: "local_education_authority",
          programme_manager: "programme_manager",
          regional_education_authority: "regional_education_authority",
          stock_movement_rows_delivery: [],
          stock_movement_totals_row_delivery: {},
          ...report.aggregates,
        },
      };
    })
    .sort((a, b) =>
      moment(`${a.year}/${a.month}/${a.start_day}`).isAfter(
        `${b.year}/${b.month}/${b.start_day}`
      )
        ? 1
        : -1
    );
  return fullReports;
};

/**
 * Remove local signatures when accessing rejected reports
 */
const useCleanLocalSignatures = (report: IReport | undefined) => {
  const dispatch = useDispatch();
  const currentSignatures = useSelector(
    getFilteredReportSignatures({
      reportMonth: report ? report.month : null,
      reportYear: report ? report.year : null,
      reportStartDay: report ? report.start_day : null,
      reportEndDay: report ? report.end_day : null,
    } as any)
  );

  if (!report) {
    return;
  }

  if (["rejected", "open"].includes(report.state)) {
    if (currentSignatures.length !== 0) {
      dispatch(
        removeReportSignatures({
          reportMonth: report.month,
          reportYear: report.year,
          reportStartDay: report.start_day,
          reportEndDay: report.end_day,
        } as any)
      );
    }
  }
};

const Report = () => {
  const dispatch = useDispatch();
  const params = useParams<{
    item: string;
    schoolId: string;
    details: ReportAction;
  }>();
  const state = useSelector((state) => state) as RootState;
  const currentSchool = useSelector(getCurrentSchool);
  const currentSchoolYear = useSelector(getCurrentSchoolYear);
  const previousSchoolYears = useSelector(getPreviousSchoolYears);
  const reports = useSelector(reportsDuck.getList);
  const isFetching = useSelector(reportsDuck.isFetching(REPORT_FETCHER));
  const allStores = useSelector(getAllStores);
  const unsyncedStores = useSelector(getUnsyncedStores);

  const yearsMonths = getReportableMonthsBySyncables(allStores, state);

  const canFetch = !useSelector(getLoggedInOffline);

  const schoolYearsArray = [...previousSchoolYears];

  /**
   * Create the schoolYears array from by getting all valid years from
   * the previousSchoolYears.
   * Append collapsed field to every school year item
   */
  const [schoolYears, setSchoolYears] = useState(
    schoolYearsArray
      .filter((item) => (item ? true : false))
      .map((item) => ({
        ...item,
        collapsed:
          (!currentSchoolYear && schoolYearsArray.indexOf(item)) === 0
            ? false
            : true,
      }))
  );

  // Update the collapsed field for the according item (school year or month)
  function setCollapsedYear(schoolYear: Object) {
    let updatedSchoolYears: any[] = [...schoolYears];
    const index = updatedSchoolYears.indexOf(schoolYear);
    const collapsed = updatedSchoolYears[index].collapsed;
    updatedSchoolYears[index].collapsed = !collapsed;
    setSchoolYears(updatedSchoolYears);
  }

  // Try to fetch reports each time you visit the page
  useEffect(() => {
    if (!canFetch) {
      return;
    }
    dispatch(
      reportsDuck.fetchList(REPORT_FETCHER, { school: currentSchool.id })
    );
  }, [dispatch, currentSchool.id, canFetch]);

  const reportsAndPreviews = getReportAndPrevious(
    yearsMonths,
    reports,
    previousSchoolYears.length > 0
      ? previousSchoolYears[0].starts_on
      : currentSchoolYear?.starts_on,
    currentSchoolYear?.ends_on ||
      (previousSchoolYears.length > 0
        ? previousSchoolYears[0].ends_on
        : undefined)
  );

  const matchingReport = reportsAndPreviews.find(
    (report) => report.client_id === params.item
  );

  let selectedReport;
  // If the URL matches a possible report or preview
  if (matchingReport) {
    const needsSyncing =
      !matchingReport.id ||
      (matchingReport.state === "open" && unsyncedStores.length !== 0);
    if (!needsSyncing) {
      // We can use BE reports if they are not in an open state,
      // or if they are open but everything is synced.
      selectedReport = matchingReport;
    } else {
      const previousReportIndex =
        reportsAndPreviews.findIndex(
          (item) => item.client_id === matchingReport.client_id
        ) - 1;

      const previousReport =
        previousReportIndex >= 0
          ? reportsAndPreviews[previousReportIndex]
          : undefined;

      // Otherwise, generate a preview
      selectedReport = generateReportPreview({
        state,
        year: matchingReport.year,
        month: matchingReport.month,
        startDay: matchingReport.start_day,
        endDay: matchingReport.end_day,
        previousReportDates: previousReport
          ? {
              year: previousReport.year,
              month: previousReport.month,
              startDay: previousReport.start_day,
              endDay: previousReport.end_day,
            }
          : undefined,
      });
    }
  }

  useCleanLocalSignatures(selectedReport);

  const reportsAndPreviewsBySchoolYear = (schoolYear: any) => {
    return reportsAndPreviews
      .filter((report) => {
        const reportStartDateValid = moment(
          `${report.year}/${report.month}/${report.start_day}`
        ).isBetween(schoolYear?.starts_on, schoolYear?.ends_on, "days", "[]");
        const reportEndDateValid = moment(
          `${report.year}/${report.month}/${report.end_day}`
        ).isBetween(schoolYear?.starts_on, schoolYear?.ends_on, "days", "[]");
        return reportStartDateValid && reportEndDateValid;
      })
      .sort((a, b) => {
        const aDate = moment(`${a.year}/${a.month}/${a.start_day}`);
        const bDate = moment(`${b.year}/${b.month}/${b.end_day}`);
        if (aDate.isBefore(bDate, "month")) {
          return 1;
        } else {
          return -1;
        }
      });
  };

  // UX: immediately redirect to first available report if none is selected
  if (!selectedReport && reportsAndPreviews.length > 0) {
    if (currentSchoolYear) {
      return (
        <Redirect
          to={`/school/${params.schoolId}/report/${
            reportsAndPreviewsBySchoolYear(currentSchoolYear)[0]?.client_id
          }?nav=true`}
        />
      );
    }
    return (
      <Redirect
        to={`/school/${params.schoolId}/report/${
          reportsAndPreviewsBySchoolYear(schoolYears[0])[0]?.client_id
        }?nav=true`}
      />
    );
  }

  // UX: suggest starting a School Year if no data has been entered yet
  if (reportsAndPreviews.length === 0 && !currentSchoolYear) {
    return <StartASchoolYearFirst />;
  }

  return (
    <>
      {/* Report actions modals */}
      {{
        amend: <ReportModalAmend report={selectedReport} />,
        approve: <ReportModalApprove report={selectedReport} />,
        close: <ReportModalClose report={selectedReport} />,
        reject: <ReportModalReject report={selectedReport} />,
        reopen: <ReportModalReopen report={selectedReport} />,
        reopen_approved: (
          <ReportModalReopen
            report={selectedReport}
            action={"reopen_approved"}
          />
        ),
        reopen_validated: (
          <ReportModalReopen
            report={selectedReport}
            action={"reopen_validated"}
          />
        ),
        sign: <ReportModalSign report={selectedReport} />,
        submit: <ReportModalSubmit report={selectedReport} />,
        update: <></>,
      }[params.details] || null}

      <SidebarWrapper>
        {isFetching && <InlineLoading />}
        {currentSchoolYear &&
          reportsAndPreviewsBySchoolYear(currentSchoolYear).map((report) => (
            <ReportItem report={report} key={report.client_id} />
          ))}
        <SchoolYearGroupDropdown
          schoolYears={schoolYears}
          setCollapsedYearOrMonth={setCollapsedYear}
          currentSchoolYear={currentSchoolYear}
          reportsAndPreviewsBySchoolYear={reportsAndPreviewsBySchoolYear}
          type={"reports"}
        />
      </SidebarWrapper>
      {selectedReport && <ReportContent report={selectedReport} />}
    </>
  );
};

export default Report;
