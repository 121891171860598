import React from "react";
import { useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import withSizes from "react-sizes";

import {
  faClipboardUser,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Module, ModuleBody } from "@wfp/ui";

import { takeHomeRationCategory, UserPermissionRoles } from "SCConstants";
import { mapSizesToProps } from "helpers/mapSizesToProps";
import { buildAttendanceUrl } from "helpers/attendance";
import { getReportedStores } from "helpers/report";
import {
  getCurrentSchoolYear,
  getIsDateLocked,
  getIsSyncableLocked,
  getPreviousSchoolYears,
  getSchoolYearByDate,
  getSchoolYearLevelsBySchoolYearId,
} from "data-handler/ducks/stores";

import DeleteModalButton from "components/DeleteModalButton";
import Empty from "components/Empty";
import Section from "components/Section";
import SubTitle from "components/SubTitle";
import Gate from "components/Gate";
import AttendanceTableMobile from "components/AttendanceShow/AttendanceTableMobile";
import AttendanceTableDesktop from "components/AttendanceShow/AttendanceTableDesktop";

import { getIdToNoMealReasonMapping } from "data-handler/ducks/noMealReasons";

import UneditableMessage from "components/UneditableMessage";

import moment from "moment";

const ThrAttendanceShow = ({
  currentStoreData,
  date,
  isMobile,
  currentSchoolEnabledTHR = false,
}) => {
  const params = useParams();

  const urlDetails = currentStoreData
    ? "take-home-ration-data"
    : "new-take-home-ration-data";

  const editUrl = buildAttendanceUrl(
    date,
    params.schoolId,
    params.item,
    params.thrItem,
    params.studentItem,
    urlDetails
  );

  const reportedStores = useSelector(getReportedStores);
  const isReported = reportedStores[params.item];
  const isLocked = useSelector((state) =>
    currentStoreData
      ? getIsSyncableLocked(state, currentStoreData)
      : getIsDateLocked(state, date)
  );

  const idToNoMealReasonMapping = useSelector(getIdToNoMealReasonMapping);

  const isNoMealDay = (
    currentStoreData?.consumption?.no_meal_reasons || []
  ).some((rId) => idToNoMealReasonMapping[rId]?.is_no_school_day);

  const noMealReasonsRestriction =
    isNoMealDay && currentStoreData?.type !== "delete" ? true : false;

  const deleteTakeHomeRationDataLabel = (
    <FormattedMessage
      id="delete.deleteTakeHomeRationData"
      defaultMessage="Delete take-home ration data"
    />
  );

  const currentYear = useSelector((state) => getCurrentSchoolYear(state)); //users current year

  const selectedAttendanceSchoolYear = useSelector(
    getSchoolYearByDate(date ? moment(date) : moment().format("YYYY-MM-DD"))
  );

  const selectedAttendanceSchoolYearLevels = useSelector(
    getSchoolYearLevelsBySchoolYearId(selectedAttendanceSchoolYear.object_id)
  );

  const previousSchoolYears = useSelector(getPreviousSchoolYears)[0];
  if (!currentStoreData?.levels) {
    return (
      <Gate isForbidden={!currentSchoolEnabledTHR}>
        <Empty
          kind="section"
          title={
            <FormattedMessage
              id="AttendanceShow.THRattendanceTitle"
              defaultMessage="Take-home ration data"
            />
          }
          icon={<FontAwesomeIcon icon={faClipboardUser} size="1x" />}
          button={
            <Gate
              forbidRoles={[UserPermissionRoles.is_viewer]}
              isForbidden={
                isLocked ||
                !currentSchoolEnabledTHR ||
                (currentYear !== null &&
                  currentYear !== selectedAttendanceSchoolYear) ||
                (currentYear === null &&
                  previousSchoolYears !== selectedAttendanceSchoolYear)
              }
            >
              <NavLink className="edit-nav-link" to={editUrl}>
                <Button
                  kind="accent"
                  icon={<FontAwesomeIcon icon={faChevronRight} />}
                  disabled={isReported || noMealReasonsRestriction}
                >
                  <FormattedMessage
                    id="AttendanceShow.provideTHRAttendanceButton"
                    defaultMessage="Provide take-home ration data"
                  />
                </Button>
              </NavLink>
            </Gate>
          }
        >
          <FormattedMessage
            id="AttendanceShow.THRprovideAttendanceLabel"
            defaultMessage="Provide take-home ration data"
          />
        </Empty>
      </Gate>
    );
  }

  return (
    <Section>
      <SubTitle
        additional={
          <Gate
            isForbidden={
              isLocked ||
              (currentYear !== null &&
                currentYear !== selectedAttendanceSchoolYear) ||
              (currentYear === null &&
                previousSchoolYears !== selectedAttendanceSchoolYear) ||
              !currentSchoolEnabledTHR
            }
            forbidRoles={[UserPermissionRoles.is_viewer]}
          >
            {currentStoreData.type !== "delete" && (
              <DeleteModalButton
                currentStoreData={currentStoreData}
                section={"attendance"}
                title={deleteTakeHomeRationDataLabel}
                customRedirectPath={
                  currentStoreData?.sync === false && !currentStoreData?.id
                    ? buildAttendanceUrl(
                        date,
                        params.schoolId,
                        params.item,
                        undefined,
                        params.studentItem
                      )
                    : buildAttendanceUrl(
                        date,
                        params.schoolId,
                        params.item,
                        params.thrItem,
                        params.studentItem
                      )
                }
              />
            )}
            {currentStoreData?.sync === false &&
              currentStoreData?.type === "delete" && (
                <label className="deleted-label">
                  <FormattedMessage
                    id="SyncIcon.deleted"
                    defaultMessage="Deleted"
                  />
                </label>
              )}

            <NavLink className="edit-nav-link" to={editUrl}>
              <Button
                kind="secondary"
                icon={<FontAwesomeIcon icon={faChevronRight} />}
                disabled={isReported || isLocked}
              >
                <FormattedMessage
                  id="THRAttendanceShow.editAttendanceButton"
                  defaultMessage="Edit take-home ration data"
                />
              </Button>
            </NavLink>
          </Gate>
        }
      >
        <Section>
          <UneditableMessage currentStoreData={currentStoreData} />
        </Section>

        <FormattedMessage
          id="THRAttendanceShow.attendanceSubTitle"
          defaultMessage="Take-home ration participants"
        />
      </SubTitle>
      <Module noMargin>
        <ModuleBody noPadding>
          {!isMobile ? (
            <AttendanceTableDesktop
              attendance={currentStoreData}
              schoolYear={selectedAttendanceSchoolYear}
              schoolYearLevels={selectedAttendanceSchoolYearLevels}
              category={takeHomeRationCategory}
            />
          ) : (
            <AttendanceTableMobile
              attendance={currentStoreData}
              schoolYear={selectedAttendanceSchoolYear}
              schoolYearLevels={selectedAttendanceSchoolYearLevels}
              category={takeHomeRationCategory}
            />
          )}
        </ModuleBody>
      </Module>
    </Section>
  );
};

export default withSizes(mapSizesToProps)(ThrAttendanceShow);
