import axios, { AxiosError, AxiosResponse } from "axios";
import { call, put, takeLatest } from "@redux-saga/core/effects";
import storage from "redux-persist/lib/storage";

import { persistReducer } from "redux-persist";
import { RootState } from "data-handler/rootReducer";
export const NO_MEAL_REASONS_REQUEST = "schoolconnect/nomealreasons/REQUEST";
export const NO_MEAL_REASONS_SUCCESS = "schoolconnect/nomealreasons/SUCCESS";
export const NO_MEAL_REASONS_FAILURE = "schoolconnect/nomealreasons/FAILURE";

export type NoMealReason = {
  id: number;
  country: number;
  is_no_school_day: boolean;
  name: string;
};

type NoMealReasonsData = {
  count: number;
  next: null | string;
  previous: null | string;
  results: NoMealReason[];
};

export type IdToNoMealReasonMapping = { [ReasonId: number]: NoMealReason };

type State = {
  noMealReasonsFetching: boolean;
  noMealReasonsError: any;
  noMealReasonsData: NoMealReasonsData;
};

type NoMealReasonsRequestAction = {
  type: typeof NO_MEAL_REASONS_REQUEST;
  countryId: number;
};

type NoMealReasonsSuccessAction = {
  type: typeof NO_MEAL_REASONS_SUCCESS;
  data: NoMealReasonsData;
};

type NoMealReasonsFailureAction = {
  type: typeof NO_MEAL_REASONS_FAILURE;
  error: AxiosError;
};

type Action =
  | NoMealReasonsRequestAction
  | NoMealReasonsSuccessAction
  | NoMealReasonsFailureAction;

const EMPTY_NO_MEAL_REASON_DATA: NoMealReasonsData = {
  count: 0,
  next: null,
  previous: null,
  results: [],
};

const initialState: State = {
  noMealReasonsFetching: false,
  noMealReasonsError: null,
  noMealReasonsData: EMPTY_NO_MEAL_REASON_DATA,
};

const reducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case NO_MEAL_REASONS_REQUEST:
      return {
        ...state,
        noMealReasonsFetching: true,
        noMealReasonsError: null,
      };
    case NO_MEAL_REASONS_SUCCESS:
      return {
        ...state,
        noMealReasonsFetching: false,
        noMealReasonsError: null,
        noMealReasonsData: action.data,
      };
    case NO_MEAL_REASONS_FAILURE:
      return {
        ...state,
        noMealReasonsFetching: false,
        noMealReasonsError: action.error,
      };
    default:
      return state;
  }
};

const persistedReducer = persistReducer(
  {
    key: "noMealReasons",
    storage,
    blacklist: ["noMealReasonsFetching", "noMealReasonsError"],
  },
  reducer
);

export default persistedReducer;

function fetchNoMealReasons(action: NoMealReasonsRequestAction) {
  const { countryId } = action;
  const url = `${process.env.REACT_APP_API_URL}/no-meal-reasons/${
    countryId ? `?country=${countryId}` : ""
  }`;
  return axios({
    method: "GET",
    url: url,
  });
}

export function* workerNoMealReasonsSaga(action: NoMealReasonsRequestAction) {
  try {
    const response: AxiosResponse = yield call(fetchNoMealReasons, action);
    const data = {
      ...response.data,
    };
    yield put({ type: NO_MEAL_REASONS_SUCCESS, data });
  } catch (error) {
    yield put({ type: NO_MEAL_REASONS_FAILURE, error });
  }
}

export function* noMealReasonsSagaWatcher() {
  yield takeLatest(NO_MEAL_REASONS_REQUEST, workerNoMealReasonsSaga);
}

export const requestNoMealReasons = (countryId: number) => ({
  type: NO_MEAL_REASONS_REQUEST,
  countryId,
});

export const getNoMealReasons = (state: RootState): NoMealReason[] =>
  state.noMealReasons.noMealReasonsData.results;

export const getIdToNoMealReasonMapping = (
  state: RootState
): IdToNoMealReasonMapping => {
  const idToReasonMapping: IdToNoMealReasonMapping = {};
  for (const reason of getNoMealReasons(state)) {
    idToReasonMapping[reason.id] = reason;
  }
  return idToReasonMapping;
};

export const getSourceById = (id: number) => (state: RootState) =>
  getNoMealReasons(state).find((noMealReason) => noMealReason.id === id);

export const getIsNoMealReasonsFetching = (state: RootState): boolean =>
  state.noMealReasons.noMealReasonsFetching;

export const getNoMealReasonsError = (state: RootState): AxiosError =>
  state.noMealReasons.noMealReasonsError;
